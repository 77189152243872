export default function PrivacyPolicy() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                        <div className="d-flex flex-column">
                            <h3 className="pb-4">Privacy Policy</h3>
                            <div className="row">
                            <div className="col-md-12 font-weight-5 para-txt">
                                <div className="disInline">
                                <p className="pb-2">
                                    This privacy notice discloses the privacy practices for{" "}
                                    <a href="www.iskconvrindavan.com" target="_blank">
                                    www.iskconvrindavan.com
                                    </a>
                                    . This privacy notice applies solely to information collected by
                                    this website. It will notify you of the following:
                                </p>
                                <ol type={1} className="pb-4">
                                    <li>
                                    <p>
                                        What personally identifiable information is collected from
                                        you through the website, how it is used and with whom it may
                                        be shared.
                                    </p>
                                    </li>
                                    <li>
                                    <p>
                                        What choices are available to you regarding the use of your
                                        data.
                                    </p>
                                    </li>
                                    <li>
                                    <p>
                                        The security procedures in place to protect the misuse of
                                        your information.
                                    </p>
                                    </li>
                                    <li>
                                    <p>
                                        How you can correct any inaccuracies in the information.
                                    </p>
                                    </li>
                                </ol>
                                <p className="pb-2">
                                    <b>Information Collection, Use, and Sharing</b>
                                </p>
                                <p className="pb-2">
                                    We are the sole owners of the information collected on this
                                    site. We only have access to collect information that you
                                    voluntarily give us via email or other direct contact from you.
                                    We will not sell or rent this information to anyone.
                                </p>
                                <p className="pb-2">
                                    We will use your information to respond to you, regarding the
                                    reason you contacted us. We will not share your information with
                                    any third party outside of our organization, other than as
                                    necessary to fulfil your request, e.g. to ship an order.
                                </p>
                                <p className="pb-4">
                                    Unless you ask us not to, we may contact you via email in the
                                    future to tell you about specials, new products or services, or
                                    changes to this privacy policy.
                                </p>
                                <p className="pb-2">
                                    <b>Your Access to and Control Over Information</b>
                                </p>
                                <p className="pb-2">
                                    You may opt out of any future contacts from us at any time. You
                                    can do the following at any time by contacting us via the email
                                    address or phone number given on our website:
                                </p>
                                <ol type={1} className="pb-4">
                                    <li>
                                    <p>See what data we have about you, if any.</p>
                                    </li>
                                    <li>
                                    <p>Change/correct any data we have about you.</p>
                                    </li>
                                    <li>
                                    <p>Have us delete any data we have about you.</p>
                                    </li>
                                    <li>
                                    <p>
                                        Express any concern you have about our use of your data.
                                    </p>
                                    </li>
                                </ol>
                                <p className="pb-2">
                                    <b>Security</b>
                                </p>
                                <p className="pb-2">
                                    We take precautions to protect your information. When you submit
                                    sensitive information via the website, your information is
                                    protected both online and offline.
                                </p>
                                <p className="pb-2">
                                    While we use encryption to protect sensitive information
                                    transmitted online, we also protect your information offline.
                                    Only employees who need the information to perform a specific
                                    job (for example, billing or customer service) are granted
                                    access to personally identifiable information. The computer
                                    servers in which we store personally identifiable information
                                    are kept in a highly secure environment.
                                </p>
                                <p className="pb-2">
                                    If you feel that we are not abiding by this privacy policy, you
                                    should contact our business head immediately via telephone at
                                    18001030108 or via email at{" "}
                                    <a href="info@iskconvrindavan.com" target="_blank">
                                    info@iskconvrindavan.com
                                    </a>
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}