import { Link } from "react-router-dom";

export default function Iskon() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">ISKCON</h3>
                        <p>
                        The Sri Sri Radha Shyamsundar Mandir, situated in the Varachha area of Surat, 
                        holds a special significance for the devotees of the
                        International Society for Krishna Consciousness. This grand temple
                        represents the fulfilled dream of Srila Prabhupada, Founder Acharya of
                        ISKCON, who wished to construct a temple of unparalleled beauty for
                        the worship of the transcendental brothers Krishna and Balaram in the
                        same village where They played more than five thousand years ago.
                        </p>
                    </div>
                    <div className="w-100 mt-4  service-img-wraping">
                        <div className="row flex-wrap">
                        <div className="col-md-3">
                            <div className="card">
                            <img
                                src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-2064b14c69070f6.jpg"
                                alt="About Us"
                                className="card-img-top"
                            />
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    About Us
                                </h5>
                                <Link
                                    to="/home/about-us"
                                    className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                >
                                    Know More
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img
                                src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-1564b14c69520c1.jpeg"
                                alt="The History"
                                className="card-img-top"
                            />
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    The History
                                </h5>
                                <Link
                                    to="/home/the-history"
                                    className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                >
                                    Know More
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img
                                src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-364b14c69886c4.jpeg"
                                alt="Philosophy"
                                className="card-img-top"
                            />
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    Philosophy
                                </h5>
                                <Link
                                    to="/home/philosophy"
                                    className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                >
                                    Know More
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img
                                src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-1964b14c69b31bf.jpeg"
                                alt="Founder"
                                className="card-img-top"
                            />
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    Founder
                                </h5>
                                <Link
                                    to="/home/founder"
                                    className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                >
                                    Know More
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img
                                src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-2964b14c69d72b0.png"
                                alt="Why This Krishna Conscious Movement?"
                                className="card-img-top"
                            />
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    Why This Krishna Conscious Movement?
                                </h5>
                                <Link
                                    to="/home/why-krishna-conscious-movement"
                                    className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                >
                                    Know More
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}