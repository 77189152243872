import { Link } from 'react-router-dom';
import blogListJson from './blogList.json';

export default function Blog() {
    const blogList = blogListJson['blogList'];
    return (
        <div className="container-fluid blog">
            <div className="container">
                <div className="w-100 service-area slide-silk-2 py-5 body-text ">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">Blog</h3>
                    </div>
                    <div className="w-100 mt-4">
                        <div className="row flex-wrap">
                            {
                                blogList.map((value, key) => {
                                    return (
                                        <div className="col-md-3" key={key}>
                                            <div className="card">
                                            <img
                                                src={value['imgUrl']}
                                                className="card-img-top"
                                                alt={value['title']}
                                            />
                                            <div className="card-body">
                                                <div className="d-flex align-items-center font-12 font-weight-6 justify-content-between mb-3">
                                                <div className="d-flex align-items-center">
                                                    <span className="blog-ico-wrap me-2">
                                                    <img src={value['adminIcon']} alt="" />
                                                    </span>
                                                    <span className="video-date">{value['adminName']}</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span className="blog-ico-wrap me-2">
                                                    <img src="/images/calendar-donate.svg" alt="" />
                                                    </span>
                                                    <span className="video-date">{value['postDate']}</span>
                                                </div>
                                                </div>
                                                <h5 className="card-title font-14 font-weight-8 line-height-21">
                                                {value['title']}
                                                </h5>
                                                <p className="card-text">
                                                {value['desc']}
                                                </p>
                                                <Link
                                                to={value['slug']}
                                                className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                                >
                                                {value['btnText']}
                                                </Link>
                                            </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}