import { Link } from 'react-router-dom';
import dailyDarshan from './dailyDarshan.json';
import Stories from 'react-insta-stories';
import { useState } from 'react';


export default function DailyDarshan() {
    const dailyDarshanList = dailyDarshan['dailyDarshanList'];
    const [storyList, setStoryList] = useState(dailyDarshanList[0]['listImg']);
    // let navigate = useNavigate();

    const redirectFn = (path) => {
        window.open(path, '_blank');
        // navigate(path);
    }
    return (
        <>
        
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 donate image-gallery">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-5 flex-column flex-md-row">
                            <div className="me-auto mb-4 mb-md-0 darshan">
                                <h3 className="">Gallery</h3>
                            </div>
                        </div>
                        <div>
                        <div className="row">
                            {
                                dailyDarshanList.map((value, key) => {
                                    return (
                                        <div key={key} className="col-md-3 mb-5">
                                            <div className="puja-bx cursor-pointer" data-bs-toggle="modal" data-bs-target="#darshanModal" onClick={()=>setStoryList(value['listImg'])}>
                                                <div className="w-100 position-relative">
                                                <img
                                                    src={value['imgUrl']}
                                                    alt=""
                                                    className="d-block w-100 offer-p dargal"
                                                />
                                                </div>
                                                <div className="px-3 py-4 w-100 bg-white rounded-bottom">
                                                <div className="puja-content">
                                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine" />
                                                    <div className="d-flex align-items-center font-12 font-weight-6">
                                                    <img src="/images/time.svg" className="me-2" alt="" />{" "}
                                                    <span className="video-date">{value['postDate']}</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="backdrop-iskon" /></div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="darshanModal" tabIndex="-1" aria-labelledby="LoaderModalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content noti-modal">
                        <div className="modal-body">
                            <div className='container-fluid'>
                                <div className='container center-flex'>
                                    <Stories
                                        loop
                                        keyboardNavigation
                                        stories={storyList}
                                        defaultInterval={3000}
                                        // width={432}
                                        // height={768}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}