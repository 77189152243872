import { useNavigate, useParams } from 'react-router-dom';
import blogDetailJson from './blogDetail.json';
import _ from 'lodash';
const slugWiseDetail = _.keyBy(blogDetailJson['blogDetails'],'slug');
const donateNowList = blogDetailJson['donateNow'];

export default function BlogDetail() {
    const { slug } = useParams();
    const mainDetail = slugWiseDetail[slug];

    let navigate = useNavigate();

    const redirectFn = (path) => {
        navigate(path);
    }

    return (
        <div className="container-fluid blog">
            <div className="container">
                <div className="w-100 service-area slide-silk-2 py-5 body-text ">
                <div className="d-flex flex-column">
                    <h3 className="pb-4 font-weight-8 blog-heading">
                    {mainDetail['title']}
                    </h3>
                </div>
                <div className="w-100">
                    <div className="row">
                        <div className="col-md-9 pe-md-5">
                            <img src={mainDetail['imgUrl']} alt={mainDetail['title']} className="blog-banner d-md-flex"/>
                            <div className="d-flex align-items-center font-12 font-weight-6 py-4">
                                <div className="d-flex align-items-center me-4">
                                    <span className="blog-ico-wrap me-2">
                                    <img src={mainDetail['adminIcon']} alt="" />
                                    </span>
                                    <span className="video-date">{mainDetail['adminName']}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className="blog-ico-wrap me-2">
                                    <img src="/images/calendar-donate.svg" alt="" />
                                    </span>
                                    <span className="video-date">{mainDetail['postDate']}</span>
                                </div>
                            </div>
                            <div className="blog-content font-16 font-weight-5 line-h-26" dangerouslySetInnerHTML={{__html: _.unescape(mainDetail['bodyHtml'])}}>
                            </div>
                            <div className="social-wrap w-100 ">
                                {/* <h6 className="font-16 line-h-26 font-weight-6">Share Blog</h6>
                                <ul className="d-flex align-items-center py-2">
                                    <li className="me-1">
                                    <img
                                        src="/images/blog/link.jpg"
                                        alt=""
                                        className="social-blog"
                                    />
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-md-3 px-3">
                            <h6 className="category-heading font-weight-8 mb-4">Donate Now</h6>
                                <div className="category-box-wrap py-4">
                                    <ul>
                                        {
                                            donateNowList.map((value, key) => {
                                                return (
                                                    <li className="font-14 font-weight-6 mb-4 d-flex cursor-pt" key={key} onClick={()=>redirectFn('/donate')}>
                                                        <div className="flex-shrink-0">
                                                            <img src={value['icon']} alt="" className="catg-img me-2"/>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {value['title']}
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}