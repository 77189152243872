export default function History() {
    return (
        <>
            <div className="container-fluid pt-4">
                <div className="container">
                    <div className="w-100">
                    <div
                        id="CulturalCR"
                        className="carousel slide carousel-fade d-md-flex d-none"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                        <div className="carousel-item">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        <div className="carousel-item active">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        </div>
                        <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="prev"
                        >
                        <span
                            className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs-arw" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="next"
                        >
                        <span
                            className="carousel-control-next-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs" />
                        </span>
                        <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">The History</h3>
                        <div className="row">
                        <div className="col-md-12 font-weight-5 para-txt">
                            <div className="disInline">
                            <p className="pb-4">
                                The International Society for Krishna Consciousness (ISKCON) was
                                founded in New York City in 1966 by His Divine Grace A.C.
                                Bhaktivedanta Swami Prabhupada, who went to the western world
                                with the mission of spreading the transcendental message of Sri
                                Chaitanya Mahaprabhu, the Supreme Personality of Godhead
                                appearing as a devotee in this age of Kali. Being a part of the
                                Brahma Madhva Gaudiya Vaishnava Sampradaya, one of the four
                                vaishnava sampradayas, ISKCON comes in an authorized disciplic
                                succession of great spiritual preceptors who have taught the
                                science of self realization based on the teachings of the
                                Bhagawat Gita and Srimad Bhagwatam.
                            </p>
                            <p className="pb-4">
                                The precepts and practices of ISKCON were taught and codified by
                                Sri Chaitanya Mahaprabhu (1486-1532) along with His brother
                                Nityananda Prabhu and six of His principle associates, the
                                Goswamis of Vrindavana (Sanatana, Rupa, Jiva, Gopal Bhatta,
                                Raghunatha Dasa and Raghunatha Bhatta).
                            </p>
                            <p className="pb-4">
                                The Bhagavad-gita, the principal scripture of The Hare Krishna
                                Movement, is an eternal teaching, having Its’ written origins
                                more than 5000-years ago, and before that time it was an oral
                                tradition handed down from teacher to student from time
                                immemorial.
                            </p>
                            <p className="pb-4">
                                Sri Chaitanya gave a powerful impetus for a massive bhakti
                                (devotional) movement throughout India. Under His direction
                                hundreds of volumes on the philosophy of Krishna consciousness
                                were compiled. Many devotees followed in the preceptorial line
                                of Sri Chaitanya Mahaprabhu including in the 19th century an
                                outstanding Vaishnava theologian, Bhaktivinoda Thakura
                                (1838-1914) who brought Krishna consciousness to a modern
                                audience by first sending a book on the Teachings of Lord
                                Caitanya to McGill University in Canada in 1896. Bhaktivinoda
                                Thakur’s son, Bhaktisiddhanta Sarasvati Goswami (1874- 1937),
                                became the guru of Srila Prabhupada (1896-1977) and instructed
                                him to spread Krishna consciousness to the English speaking
                                people of the West. On this order, Srila A.C. Bhaktivedanta
                                Swami Prabhupada took a perilous journey across the Atlantic
                                Ocean to USA in 1965, and consequently started the most
                                auspicious spiritual movement, The International Society for
                                Krishna Consciousness, for the fulfillment of his guru’s desire.
                                ISKCON quickly spread to the major cities of the whole world in
                                a mere 11 years.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

