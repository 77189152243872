import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>        
            <div className="backdrop-iskon"></div>
            <div className="container-fluid footer-element">
            <div className="container">
                <footer className="py-5">
                <div className="d-flex bd-highlight mb-3 flex-column flex-md-row">
                    <div className="me-auto">
                    <div className="d-flex flex-column flex-column">
                        <div className="d-flex flex-grow-1 align-items-center mb-4">
                        <img
                            src="/images/logo-iskon.png"
                            alt=""
                            className="logo-iskon me-3"
                        />
                        <span className="logo-text font-weight-8 text-uppercase">
                            ISKCON
                            <br />
                            VARACHHA
                        </span>
                        </div>
                        <p className="mb-4 font-weight-5">
                            ISKCON VARACHHA Temple, Opp. Bhavani mata <br/>
                            temple, Matavadi circle, LH road, Varachha<br/>
                            Surat, Gujarat-395006, India
                        </p>
                        <ul className="d-flex gap-3">
                            <li>
                                <a
                                href="https://www.facebook.com/iskconvarachha/"
                                target="_blank"
                                className="social-icon d-flex align-items-center justify-content-center"
                                >
                                <img src="/images/facebook_icon.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://twitter.com/iskconvarachha"
                                target="_blank"
                                className="social-icon d-flex align-items-center justify-content-center"
                                >
                                <img src="/images/twitter_icon.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://www.instagram.com/iskconvarachha"
                                target="_blank"
                                className="social-icon d-flex align-items-center justify-content-center"
                                >
                                <img src="/images/instagram_icon.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://www.youtube.com/c/murtimandasgkg"
                                target="_blank"
                                className="social-icon d-flex align-items-center justify-content-center"
                                >
                                <img src="/images/youtube_icon.svg" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-evenly flex-column flex-md-row mt-md-0 mt-4">
                    <div className="p-2 footer-listing me-4">
                        <h3 className="mb-4">Timing</h3>
                        <ul>
                        <li>
                            <span className="d-flex align-items-center">
                            <img
                                src="/images/footer-list.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Mangala Arati – 04:30AM</span>
                            </span>
                        </li>
                        <li>
                            <span className="d-flex align-items-center">
                            <img
                                src="/images/footer-list.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Tulsi-Puja – 05:00AM</span>
                            </span>
                        </li>
                        <li>
                            <span className="d-flex align-items-center">
                            <img
                                src="/images/footer-list.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Sringar Darshan – 07:15AM</span>
                            </span>
                        </li>
                        <li>
                            <span className="d-flex align-items-center">
                            <img
                                src="/images/footer-list.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Guru Puja – 07:25AM</span>
                            </span>
                        </li>
                        <li>
                            <span className="d-flex align-items-center">
                            <img
                                src="/images/footer-list.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Bhagavatam Class – 08:00AM</span>
                            </span>
                        </li>
                        </ul>
                    </div>
                    <div className="p-2 footer-listing me-4">
                        <h3 className="mb-4">Quick Access</h3>
                        <ul>
                        <li>
                            <Link
                            to="/home/about-us"
                            className="d-flex align-items-center"
                            >
                            <img
                                src="/images/footer-list2.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>About Us</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                            to="/home/founder"
                            className="d-flex align-items-center"
                            >
                            <img
                                src="/images/footer-list2.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Founder</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                            to="/home/philosophy"
                            className="d-flex align-items-center"
                            >
                            <img
                                src="/images/footer-list2.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Philosophy</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                            to="donate"
                            className="d-flex align-items-center"
                            >
                            <img
                                src="/images/footer-list2.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Donate</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                            to="/blog"
                            className="d-flex align-items-center"
                            >
                            <img
                                src="/images/footer-list2.svg"
                                alt=""
                                className="list-foot me-2"
                            />
                            <span>Blog</span>
                            </Link>
                        </li>
                        </ul>
                    </div>
                    <div className="p-2 footer-listing me-4 contact">
                        <h3 className="mb-4">Contact us</h3>
                        <ul>
                        <li className="d-flex align-items-center mb-4">
                            <span className="call-ico-bg d-flex align-items-center justify-content-center">
                            <img
                                src="/images/call-footer.svg"
                                alt=""
                                className="call-ico"
                            />
                            </span>
                            <span>
                            <a href="tel:+917618183108">+919712000909</a>
                            </span>
                        </li>
                        <li className="d-flex align-items-center">
                            <span className="call-ico-bg d-flex align-items-center justify-content-center">
                            <img src="/images/email.svg" alt="" className="call-ico" />
                            </span>
                            <span>
                            <a href="mailto:iskconvarachha@gmail.com">
                            iskconvarachha@gmail.com
                            </a>
                            </span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="footer-bottom pt-4 mt-md-5 d-flex justify-content-between flex-column flex-md-row">
                    <span className="font-14 font-weight-5">
                    ©2024 ISKCON VARACHHA. All rights Reserved.
                    </span>
                    {/* <span className="mt-md-0 mt-3">
                        <span>
                            <Link to="/terms-policy/terms-and-condition">Terms And Condition</Link>
                            <span>|</span>
                        </span>
                        <span>
                            <Link to="/terms-policy/privacy-policy">Privacy Policy</Link>
                            <span>|</span>
                        </span>
                        <span>
                            <Link to="/terms-policy/refund-and-cancellation-policy">
                            Refund &amp; Cancellation Policy
                            </Link>
                        </span>
                    </span> */}
                </div>
                </footer>
            </div>
            </div>
        </>
    )
}
