import { Link } from 'react-router-dom';
import kirtanLectures from './kirtanLectures.json';

export default function KirtanLectures() {
    const kirtanLecturesList = kirtanLectures['kirtanLecturesList'];
    // let navigate = useNavigate();

    const redirectFn = (path) => {
        window.open(path, '_blank');
        // navigate(path);
    }
    return (
        <div className="container-fluid">
            <div className="container">
                <div className="w-100 service-area slide-silk-2 py-5 donate image-gallery">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-5 flex-column flex-md-row">
                            <div className="me-auto mb-4 mb-md-0 darshan">
                                <h3 className="">Kirtans &amp; Lectures</h3>
                            </div>
                        </div>
                        <div>
                            <div className="row c3 media">
                                {
                                    kirtanLecturesList.map((value, key) => {
                                        return (
                                            <div className="col-md-3 mb-5">
                                                <div className="puja-bx cursor-pointer" onClick={()=>redirectFn(value['linkUrl'])}>
                                                    <div className="w-100 position-relative">
                                                        <img src={value['thumbUrl']} alt={value['title']} className="d-block w-100 offer-p"/>
                                                        <img src="/images/youtube.png" alt="" className="position-absolute youtube-icon"/>
                                                    </div>
                                                    <div className="px-3 py-4 w-100 bg-white rounded-bottom">
                                                    <div className="puja-content">
                                                        <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine">
                                                            {value['title']}
                                                        </h5>
                                                        <div className="d-flex align-items-center font-12 font-weight-6">
                                                        <img src="/images/time.svg" className="me-2" alt="" />
                                                        <span className="video-date">{value['postDate']}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <div className="backdrop-iskon" />
                                <div className="modal fade" id="videoModal" tabIndex={-1} aria-labelledby="videoModalLabel" style={{ display: "none" }} aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <object width={840} height={460}>
                                                    <param name="movie" value="" />
                                                    <param name="allowFullScreen" value="true" />
                                                    <param name="allowscriptaccess" value="always" />
                                                    <embed width={840} height={460} src="" className="youtube-player" type="text/html" allowscriptaccess="always" allowFullScreen=""/>
                                                </object>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}