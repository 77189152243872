export default function Donate() {
    return (
        <div>
            <div className="container-fluid py-4">
                <div className="container">
                    <div className="w-100 mt-4">
                    <div className="row align-items-center">
                        <div className="col-md-7 donationPage mb-1">
                        <div className="banner-inner  px-4 py-4">
                            <div className="d-flex align-items-center flex-md-row flex-column">
                            <div className="d-flex flex-column">
                                <h2 className="main-heading text-white">
                                Sri Krishna Janmashtami Seva
                                </h2>
                                <div className="disInline">
                                <p className="text-white">
                                    <b>Q) When is Janmashtami celebrated?</b>
                                </p>
                                <p className="text-white">
                                Janmashtami, the appearance day of Lord Krishna, is celebrated on Eighth day of Krishnapaksh of Bhadrapad month of Lunar-calendar.
                                </p>
                                <button id="sp_10758" type="button" className="btn bg-white btn-white font-weight-5 mt-3" data-bs-toggle="modal" data-bs-target="#ReadMoreModal">Read more</button>
                                <div id={10758} style={{ display: "none" }}>
                                    <p className="text-white  pb-3">
                                    Pavitropana Ekadashi is celebrated on the 11th day
                                    (Ekadashi) of the Shukla Paksha (bright fortnight) in the
                                    month of Shravana (July-August)
                                    </p>
                                    <p className="text-white">
                                    <b>
                                        Q) On what date is Pavitrapana Ekadashi falling this
                                        year?
                                    </b>
                                    </p>
                                    <p className="text-white pb-3">
                                    Pavitropana Ekadashi is falling on 16th Aug, 2024 this
                                    year.
                                    </p>
                                    <p className="text-white">
                                    <b>Q) What is the Paran time for Pavitropana Ekadashi?</b>
                                    </p>
                                    <p className="text-white pb-3">
                                    The paran time for Pavitropana Ekadashi is on Dwadashi
                                    that is on 28th August.2023 between 05:50 - 08:07 (LT)
                                    </p>
                                    <p className="text-white">
                                    <b>Q) What is the story of Pavitropana Ekadashi?</b>
                                    </p>
                                    <p className="text-white">
                                    Pavitropana Ekadashi, also known as Putrada Ekadashi, is
                                    observed during the Shukla Paksha (waxing phase of the
                                    moon) in the month of Shravana or Sridhar
                                    (August-September). It is said to have the power to grant
                                    a son to those who observe it with devotion.
                                    </p>
                                    <p className="text-white">
                                    The story of Pavitropana Ekadashi is narrated in the
                                    Skanda Purana. Once, there was a king named Mahijit who
                                    ruled over the city of Mahishmati. He was a righteous and
                                    pious king but was unfortunate to have no children.
                                    Worried about his lineage, the king approached the great
                                    sage Lomasa for guidance.
                                    </p>
                                    <p className="text-white">
                                    Sage Lomasa advised the king to observe the Pavitropana
                                    Ekadashi fast and narrated the story behind it. The sage
                                    told the king about a pious Brahmin named Dhananjaya who
                                    lived in the city of Champakanagara. Dhananjaya was
                                    childless and longed for a son to continue his lineage.
                                    </p>
                                    <p className="text-white">
                                    Upon the advice of a sage, Dhananjaya observed the
                                    Pavitropana Ekadashi fast with utmost devotion. He
                                    followed all the prescribed rituals and offered prayers to
                                    Lord Vishnu. Pleased with his sincere devotion, Lord
                                    Vishnu appeared before Dhananjaya and granted him a boon.
                                    Dhananjaya requested the Lord to bless him with a son, and
                                    Lord Vishnu granted his wish.
                                    </p>
                                    <p className="text-white">
                                    Inspired by this story, King Mahijit observed the
                                    Pavitropana Ekadashi fast with great devotion. He followed
                                    all the rituals and offered prayers to Lord Vishnu and his
                                    consort, Goddess Lakshmi. As a result, the king was
                                    blessed with a son who later became a righteous ruler.
                                    </p>
                                    <p className="text-white pb-3">
                                    Thus, the Pavitropana Ekadashi is observed to seek the
                                    blessings of Lord Vishnu for progeny and to purify oneself
                                    spiritually. By observing this Ekadashi with sincerity and
                                    devotion, one can receive the grace of the Lord and
                                    fulfill their desires.
                                    </p>
                                    <p className="text-white">
                                    <b>
                                        Q) Which activities are considered auspicious to perform
                                        on Ekadashi day?/b&gt;
                                    </b>
                                    </p>
                                    <b>
                                    <p className="text-white">
                                        On Ekadashi day, there are several auspicious activities
                                        that can be performed. Here are some of them:
                                    </p>
                                    <ul className="pb-3">
                                        <li>
                                        <p className="text-white">
                                            <b>1. Fasting:</b> Observing a full fast without
                                            consuming any grains or cereals is considered highly
                                            beneficial on Ekadashi. It helps in purifying the
                                            mind and body.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>2. Chanting:</b> Observing a full fast without
                                            consuming any grains or cereals is considered highly
                                            beneficial on Ekadashi. It helps in purifying the
                                            mind and body.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>3. Reading Scriptures:</b> Spending time reading
                                            and studying Vedic scriptures, such as the Bhagavad
                                            Gita or Srimad Bhagavatam, is considered auspicious
                                            on Ekadashi. It helps in gaining spiritual knowledge
                                            and understanding.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>4. Offering Prayers:</b> Offering prayers to Lord
                                            Krishna or any other personal deity is considered
                                            auspicious. One can express their gratitude, seek
                                            blessings, and pray for spiritual advancement.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>5. Performing Charity/ Donations:</b> Engaging in
                                            acts of charity, such as donating food, clothes, or
                                            other essential items to the needy, is considered
                                            meritorious on Ekadashi. It helps in purifying one's
                                            heart and accumulating good karma
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>6. Meditation:</b> Practicing meditation or
                                            mindfulness techniques on Ekadashi can help in
                                            calming the mind, increasing concentration, and
                                            deepening one's spiritual experience.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>7. Avoiding Sleep:</b> Staying awake during the
                                            night of Ekadashi is considered beneficial. It
                                            allows one to engage in devotional activities, such
                                            as chanting or reading scriptures, and enhances
                                            spiritual progress.
                                        </p>
                                        </li>
                                        <li>
                                        <p className="text-white">
                                            <b>8. Donation:</b> If one donates for Vaishnava
                                            seva, Anna seva or Vigraha seva in the temple then
                                            this charity brings immense blessings of the Lord
                                            and the devotees.
                                        </p>
                                        </li>
                                    </ul>
                                    <p className="text-white">
                                        Remember, these activities should be performed with a
                                        sincere and pure heart, focusing on devotion and
                                        surrender to Krishna.
                                    </p>
                                    <p />
                                    </b>
                                </div>
                                </div>
                            </div>
                            <div className="d-flex banner-right-content ms-md-5 ms-0 mt-md-0 mt-4">
                                <div className="d-flex flex-column gap-4">
                                <ul className="d-flex flex-column gap-4 me-3">
                                    <li className="d-flex  align-items-center">
                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                        <img
                                        src="/images/availability.svg"
                                        alt=""
                                        className="avla"
                                        />
                                    </div>
                                    <div className="cr-text ms-3">
                                        <h6 className="mb-1">Availability</h6>
                                        <h5>Daily Darshan</h5>
                                    </div>
                                    </li>
                                    <li className="d-flex  align-items-center">
                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                        <img
                                        src="/images/community.svg"
                                        alt=""
                                        className="avla"
                                        />
                                    </div>
                                    <div className="cr-text ms-3">
                                        <h6 className="mb-1">Community</h6>
                                        <h5>Visit Iskcon Varachha</h5>
                                    </div>
                                    </li>
                                    <li className="d-flex  align-items-center">
                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                        <img src="/images/donate.svg" alt="" className="avla" />
                                    </div>
                                    <div className="cr-text ms-3">
                                        <h6 className="mb-1">Donate</h6>
                                        <h5>Prasad Distribution </h5>
                                    </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-5 donationPage mt-md-0 mt-4  mb-1">
                        <div
                            id="carouselDonate"
                            className="carousel slide carousel-fade"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="images/donate-us/d-1.png" className="d-block w-100" alt=""/>
                                </div>
                                <div className="carousel-item">
                                    <img src="images/donate-us/d-2.png" className="d-block w-100" alt=""/>
                                </div>
                                <div className="carousel-item">
                                    <img src="images/donate-us/d-3.png" className="d-block w-100" alt=""/>
                                </div>
                                <div className="carousel-item">
                                    <img src="images/donate-us/d-4.png" className="d-block w-100" alt=""/>
                                </div>
                            </div>
                            <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselDonate"
                            data-bs-slide="prev"
                            >
                            <span
                                className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
                                aria-hidden="true"
                            >
                                <img src="/images/arrow-crsl.svg" alt="" className="crs-arw" />
                            </span>
                            <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselDonate"
                            data-bs-slide="next"
                            >
                            <span
                                className="carousel-control-next-icon d-flex align-items-center justify-content-center"
                                aria-hidden="true"
                            >
                                <img src="/images/arrow-crsl.svg" alt="" className="crs" />
                            </span>
                            <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                    className="modal fade"
                    id="ReadMoreModal"
                    tabIndex={-1}
                    aria-labelledby="ReadMoreModalLabel"
                    aria-hidden="true"
                    >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-body body-text">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="m-t">
                            <p className="text-white">
                                <b>Q) When is Janmashtami celebrated?</b>
                            </p>
                            <p className="text-white">
                            Janmashtami, the appearance day of Lord Krishna, is celebrated on Eighth day of Krishnapaksh of Bhadrapad month of Lunar-calendar.
                            </p>
                            <p className="text-white">
                                <b>Q) On which date Janmashtami will be celebrated in Iskcon Varachha Surat?</b>
                            </p>
                            <p className="text-white pb-3">
                            This year Janmashtami will be celebrated on 26th August, 2024
                            </p>
                            <p className="text-white">
                                <b>Q) What is the significance of Janmashtami for ISKCON devotees</b>
                            </p>
                            <p className="text-white">
                            Janmashtami holds great significance for ISKCON devotees. It is the auspicious day that marks the appearance of Lord Krishna, the Supreme Personality of Godhead, on Earth. ISKCON devotees celebrate this occasion with utmost devotion and enthusiasm.
                            </p>
                            <p className="text-white pb-3">
                            On Janmashtami, devotees engage in various spiritual activities such as fasting, singing devotional songs, chanting the holy names of Krishna, and reading and discussing Krishna's pastimes from sacred texts like the Bhagavad Gita and Srimad Bhagavatam. They also participate in elaborate worship ceremonies and offer a variety of food preparations, known as bhoga, to Lord Krishna.
                            </p>
                            <p className="text-white">
                                <b>Q) How should one celebrate Krishna Janmashtami?</b>
                            </p>
                            <p className="text-white">
                            The celebration of Janmashtami provides an opportunity for devotees to deepen their connection with Lord Krishna and experience His divine presence. It is a time to reflect on Krishna's teachings and the significance of His appearance in this world. Janmashtami serves as a reminder for devotees to surrender themselves to the lotus feet of Lord Krishna and cultivate a loving relationship with Him.
                            </p>
                            <p className="text-white pb-3">
                            Janmashtami is not only a celebration but also a time for devotees to deepen their spiritual practice and strive for self-realization. It is an opportunity to immerse oneself in the divine pastimes and teachings of Lord Krishna, thereby progressing on the path of devotion and attaining spiritual enlightenment.
                            </p>
                            <p className="text-white">
                                <b>Q) How is Janmashtami celebrated in ISKCON temples across the globe?</b>
                            </p>
                            <p className="text-white pb-3">
                            ISKCON temples around the world organize grand festivities on Janmashtami, attracting thousands of devotees and visitors. The atmosphere is filled with joy, devotion, and spiritual fervor. The temples are beautifully decorated, and devotees engage in kirtan (devotional singing and dancing) throughout the night, awaiting the auspicious moment of Krishna's appearance.
                            </p>
                            <p className="text-white">
                                <b>Q) What activities one can engage in on the auspicious occasion of Krishna Janmashtami?</b>
                            </p>
                            <b>
                                <p className="text-white pb-3">
                                On Krishna Janmashtami, devotees engage in various activities to celebrate the appearance of Lord Krishna. Here are some activities one can perform on this auspicious day:
                                </p>
                                <ul className="pb-3">
                                <li>
                                    <p className="text-white">
                                    <b>1. Fasting:</b> Observing a fast until midnight is a common practice on Janmashtami. Devotees abstain from consuming grains and beans, and some may even go without food and water.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>2. Bhajans and Kirtans:</b> Singing devotional songs and chanting the holy names of Lord Krishna, such as the Hare Krishna mantra, is an integral part of Janmashtami celebrations. These melodious chants create a joyful and spiritual atmosphere.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>3. Temple Visits:</b> Visiting temples dedicated to Lord Krishna is a popular activity on Janmashtami. Devotees offer prayers, participate in special ceremonies, and seek the blessings of the Deities.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>4. Midnight Celebration:</b> Lord Krishna is believed to have appeared at midnight. Devotees eagerly await this moment and celebrate His birth with great enthusiasm. The midnight celebration includes special prayers, arati (ceremonial offering of light), and the breaking of the fast with prasadam (sanctified food).
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>5. Krishna Leela Performances:</b> The enchanting pastimes of Lord Krishna, known as Krishna Leela, are often reenacted through dramas, plays, or skits. These performances depict significant events from Krishna's life, such as His childhood antics, divine miracles, and teachings.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>6. Decorating Krishna's Deity:</b> Devotees beautifully adorn the Deity of Lord Krishna with colorful flowers, garlands, and new garments. The altar is decorated to create an inviting and divine ambiance.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>7. Reading and Reciting Scriptures:</b> Devotees engage in the study and recitation of sacred scriptures like the Bhagavad Gita and Srimad Bhagavatam, which contain the teachings and pastimes of Lord Krishna. This helps deepen their understanding and devotion towards Him.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>8. Offering Bhoga:</b> Devotees prepare a variety of delicious vegetarian dishes as an offering to Lord Krishna, known as bhoga. These food preparations are made with love and devotion and are later distributed as prasadam to all the attendees.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>9. Devotional Discourses:</b> Spiritual leaders and devotees deliver discourses and lectures on the glories and teachings of Lord Krishna. These enlightening talks inspire and guide individuals on their spiritual journey.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>10. Engaging in Seva:</b> Janmashtami is an opportune time to engage in selfless service, or seva, to please Lord Krishna. Devotees may volunteer in various activities like cooking, serving prasadam, cleaning the temple, or assisting in organizing the celebrations.
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                    <b>11. Donation:</b> Come forward to donate on the auspicious occasion to invite the blessings of Krishna Balram. You can donate for Gau Seva, Vigrha Seva, Vaishnav Seva and for various selfless activities carried out by Iskcon Vrindavan.
                                    </p>
                                </li>
                                </ul>
                                <p className="text-white">
                                Remember, these activities are performed with a sincere heart and devotion to Lord Krishna, aiming to deepen one's connection with Him and seek His divine blessings.
                                </p>
                                <p />
                            </b>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mb-5">
                <div className="container">
                    <div className="account-detail main-shadow py-4 px-4">
                    <h3 className="mb-4 font-weight-7">Account Detail</h3>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                        <span>
                            Account Name:{" "}
                            <strong className="font-weight-7">
                            {" "}
                            ISKCON 
                            </strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Account Number:{" "}
                            <strong className="font-weight-7"> 138501001196</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Bank Name: <strong className="font-weight-7"> ICICI Bank</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            IFSC Code: <strong className="font-weight-7"> ICIC0001385</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Branch Name:{" "}
                            <strong className="font-weight-7"> Surat Branch</strong>{" "}
                        </span>
                        </div>
                    </div>
                    <div className="pt-4 account-footer">
                        <h3 className="mb-4 font-weight-7">Support</h3>
                        <div className="d-flex flex-md-row flex-column support-text">
                        <span className="me-3">For more information please:</span>
                        <div className="d-flex align-items-center">
                            <span className="account-ico ms-2 d-flex align-items-center justify-content-center">
                            <a href="tel:+919712000909">
                                <img src="/images/calling.svg" alt="Support" />
                            </a>
                            </span>
                            <span className="account-ico ms-2 d-flex align-items-center justify-content-center">
                            <a href="https://wa.me/+919712000909">
                                <img src="/images/whatsapp.svg" alt="Support" />
                            </a>
                            </span>
                            <span className="ms-3">+919712000909</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mb-5">
                <div className="container">
                    <div className="account-detail main-shadow py-4 px-4">
                    <h3 className="mb-4 font-weight-7">Account Detail</h3>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                        <span>
                            Account Name:{" "}
                            <strong className="font-weight-7">
                            {" "}
                            ISKCON Gaushala
                            </strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Account Number:{" "}
                            <strong className="font-weight-7"> 50100258203476</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Bank Name: <strong className="font-weight-7"> HDFC Bank</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            IFSC Code: <strong className="font-weight-7"> HDFC000533</strong>{" "}
                        </span>
                        </div>
                        <div className="col-md-4 mb-4">
                        <span>
                            Branch Name:{" "}
                            <strong className="font-weight-7"> Surat Branch</strong>{" "}
                        </span>
                        </div>
                    </div>
                    <div className="pt-4 account-footer">
                        <h3 className="mb-4 font-weight-7">Support</h3>
                        <div className="d-flex flex-md-row flex-column support-text">
                        <span className="me-3">For more information please:</span>
                        <div className="d-flex align-items-center">
                            <span className="account-ico ms-2 d-flex align-items-center justify-content-center">
                            <a href="tel:+919712000909">
                                <img src="/images/calling.svg" alt="Support" />
                            </a>
                            </span>
                            <span className="account-ico ms-2 d-flex align-items-center justify-content-center">
                            <a href="https://wa.me/+919712000909">
                                <img src="/images/whatsapp.svg" alt="Support" />
                            </a>
                            </span>
                            <span className="ms-3">+919712000909</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade show"
                id="ReadMoreModal"
                tabIndex={-1}
                aria-labelledby="ReadMoreModalLabel"
                aria-modal="true"
                role="dialog"
                >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-body body-text">
                        <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                        />
                        <div className="m-t">
                        <p className="text-white">
                            <b>Q) When is Janmashtami celebrated?</b>
                        </p>
                        <p className="text-white pb-1">
                            Janmashtami, the appearance day of Lord Krishna, is celebrated on
                            Eighth day of Krishnapaksh of Bhadrapad month of Lunar-calendar.
                        </p>
                        <p className="text-white">
                            <b>
                            Q) On which date Janmashtami will be celebrated in Iskcon
                            Vrindavan?
                            </b>
                        </p>
                        <p className="text-white pb-3">
                            This year Janmashtami will be celebrated on 26th August, 2024
                        </p>
                        <p className="text-white">
                            <b>
                            Q) What is the significance of Janmashtami for ISKCON devotees
                            </b>
                        </p>
                        <p className="text-white pb-1">
                            Janmashtami holds great significance for ISKCON devotees. It is the
                            auspicious day that marks the appearance of Lord Krishna, the
                            Supreme Personality of Godhead, on Earth. ISKCON devotees celebrate
                            this occasion with utmost devotion and enthusiasm.
                        </p>
                        <p className="text-white pb-3">
                            On Janmashtami, devotees engage in various spiritual activities such
                            as fasting, singing devotional songs, chanting the holy names of
                            Krishna, and reading and discussing Krishna's pastimes from sacred
                            texts like the Bhagavad Gita and Srimad Bhagavatam. They also
                            participate in elaborate worship ceremonies and offer a variety of
                            food preparations, known as bhoga, to Lord Krishna.
                        </p>
                        <p className="text-white">
                            <b>Q) How should one celebrate Krishna Janmashtami?</b>
                        </p>
                        <p className="text-white pb-1">
                            The celebration of Janmashtami provides an opportunity for devotees
                            to deepen their connection with Lord Krishna and experience His
                            divine presence. It is a time to reflect on Krishna's teachings and
                            the significance of His appearance in this world. Janmashtami serves
                            as a reminder for devotees to surrender themselves to the lotus feet
                            of Lord Krishna and cultivate a loving relationship with Him.
                        </p>
                        <p className="text-white pb-3">
                            Janmashtami is not only a celebration but also a time for devotees
                            to deepen their spiritual practice and strive for self-realization.
                            It is an opportunity to immerse oneself in the divine pastimes and
                            teachings of Lord Krishna, thereby progressing on the path of
                            devotion and attaining spiritual enlightenment.
                        </p>
                        <p className="text-white">
                            <b>
                            Q) How is Janmashtami celebrated in ISKCON temples across the
                            globe?
                            </b>
                        </p>
                        <p className="text-white pb-3">
                            ISKCON temples around the world organize grand festivities on
                            Janmashtami, attracting thousands of devotees and visitors. The
                            atmosphere is filled with joy, devotion, and spiritual fervor. The
                            temples are beautifully decorated, and devotees engage in kirtan
                            (devotional singing and dancing) throughout the night, awaiting the
                            auspicious moment of Krishna's appearance.
                        </p>
                        <p className="text-white">
                            <b>
                            Q) What activities one can engage in on the auspicious occasion of
                            Krishna Janmashtami?
                            </b>
                        </p>
                        <p className="text-white pb-1">
                            On Krishna Janmashtami, devotees engage in various activities to
                            celebrate the appearance of Lord Krishna. Here are some activities
                            one can perform on this auspicious day:
                        </p>
                        <ul className="pb-3">
                            <li>
                            <p className="text-white pb-1">
                                <b>1. Fasting:</b> Observing a fast until midnight is a common
                                practice on Janmashtami. Devotees abstain from consuming grains
                                and beans, and some may even go without food and water.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>2. Bhajans and Kirtans:</b> Singing devotional songs and
                                chanting the holy names of Lord Krishna, such as the Hare
                                Krishna mantra, is an integral part of Janmashtami celebrations.
                                These melodious chants create a joyful and spiritual atmosphere.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>3. Temple Visits:</b> Visiting temples dedicated to Lord
                                Krishna is a popular activity on Janmashtami. Devotees offer
                                prayers, participate in special ceremonies, and seek the
                                blessings of the Deities.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>4. Midnight Celebration:</b> Lord Krishna is believed to have
                                appeared at midnight. Devotees eagerly await this moment and
                                celebrate His birth with great enthusiasm. The midnight
                                celebration includes special prayers, arati (ceremonial offering
                                of light), and the breaking of the fast with prasadam
                                (sanctified food).
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>5. Krishna Leela Performances:</b> The enchanting pastimes of
                                Lord Krishna, known as Krishna Leela, are often reenacted
                                through dramas, plays, or skits. These performances depict
                                significant events from Krishna's life, such as His childhood
                                antics, divine miracles, and teachings.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>6. Decorating Krishna's Deity:</b> Devotees beautifully adorn
                                the Deity of Lord Krishna with colorful flowers, garlands, and
                                new garments. The altar is decorated to create an inviting and
                                divine ambiance.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>7. Reading and Reciting Scriptures:</b> Devotees engage in
                                the study and recitation of sacred scriptures like the Bhagavad
                                Gita and Srimad Bhagavatam, which contain the teachings and
                                pastimes of Lord Krishna. This helps deepen their understanding
                                and devotion towards Him.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>8. Offering Bhoga:</b> Devotees prepare a variety of
                                delicious vegetarian dishes as an offering to Lord Krishna,
                                known as bhoga. These food preparations are made with love and
                                devotion and are later distributed as prasadam to all the
                                attendees.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>9. Devotional Discourses:</b> Spiritual leaders and devotees
                                deliver discourses and lectures on the glories and teachings of
                                Lord Krishna. These enlightening talks inspire and guide
                                individuals on their spiritual journey.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>10. Engaging in Seva:</b> Janmashtami is an opportune time to
                                engage in selfless service, or seva, to please Lord Krishna.
                                Devotees may volunteer in various activities like cooking,
                                serving prasadam, cleaning the temple, or assisting in
                                organizing the celebrations.
                            </p>
                            </li>
                            <li>
                            <p className="text-white pb-1">
                                <b>11. Donation:</b> Come forward to donate on the auspicious
                                occasion to invite the blessings of Krishna Balram. You can
                                donate for Gau Seva, Vigrha Seva, Vaishnav Seva and for various
                                selfless activities carried out by Iskcon Vrindavan.
                            </p>
                            </li>
                        </ul>
                        <p className="text-white pb-1">
                            Remember, these activities are performed with a sincere heart and
                            devotion to Lord Krishna, aiming to deepen one's connection with Him
                            and seek His divine blessings.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


        </div>
    )
}

