export default function Contact() {
    return (
        <div className="container-fluid">
            <div className="container">
                <div className="w-100 service-area slide-silk-2 py-5 body-text donate contact-us">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column">
                            <h3 className="pb-4">Contact Us</h3>
                        </div>
                        <div className="row form-inp">
                            <div className="col-md-6">
                                <div className="third-column main-shadow bg-white">
                                    <div className="w-100 p-4">
                                        <ul>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    <span className="icon-box d-flex align-items-center justify-content-center me-3">
                                                        <img
                                                            src="/images/reach-us.svg"
                                                            alt="ISKCON Varachha"
                                                        />
                                                    </span>
                                                    Reach us
                                                </div>
                                                <p className="font-14 font-weight-6 mb-2">
                                                    Contact to our team
                                                </p>
                                                <p className="font-17 font-weight-7 mb-0 mail-to">
                                                    <a href="mailto:iskconvarachha@gmail.com">
                                                    iskconvarachha@gmail.com
                                                    </a>
                                                </p>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    <span className="icon-box d-flex align-items-center justify-content-center me-3">
                                                        <img src="/images/call-us.svg" alt="ISKCON Varachha" />
                                                    </span>
                                                    Call us
                                                </div>
                                                <p className="font-14 font-weight-6 mb-2">
                                                    Mon - Fri (8 AM - 6 PM)
                                                </p>
                                                <p className="font-17 font-weight-7 mb-0 mail-to">
                                                    <a href="tel:+919712000909">+919712000909</a>
                                                </p>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    <span className="icon-box d-flex align-items-center justify-content-center me-3">
                                                        <img
                                                            src="/images/navigate.svg"
                                                            alt="ISKCON Varachha"
                                                        />
                                                    </span>
                                                    Navigate us
                                                </div>
                                                <p className="font-14 font-weight-6 mb-2">Google Map</p>
                                                <a target="_blank" href="https://www.google.com/maps/place/ISKCON+Varachha/@21.2056988,72.8536897,258m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!2sISKCON+Varachha!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t!3m5!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t?hl=en&entry=ttu">
                                                    <p className="font-17 font-weight-7 mb-0 mail-to cursor-pt">
                                                        ISKCON Varachha
                                                    </p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5 mt-md-0">
                                <div className="third-column main-shadow bg-white">
                                    <div className="w-100 p-4">
                                        <ul>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    Address
                                                </div>
                                                <p className="font-17 font-weight-7 mb-0 mail-to">
                                                ISKCON VARACHHA, Radha Shyam Sundar Mandir
                                                </p>
                                                <p className="font-14 font-weight-6 mb-2">
                                                Opp. Bhavani mata temple, Matavadi circle, LH road, Varachha
                                                Surat, Gujarat-395006, India
                                                </p>
                                            </li>
                                            {/* <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    Guest House
                                                </div>
                                                <p className="font-14 font-weight-6 mb-2">
                                                    Phone: (0565) 2540021, +91-8445992718
                                                </p>
                                                <p className="font-14 font-weight-6 mb-2">
                                                    Reservation Time: Monday to Saturday, 10 AM to 5 PM IST
                                                </p>
                                                <p className="font-17 mb-0 mail-to">
                                                    <a href="mailto:ganapati.gkg@pamho.net">
                                                        Email: ganapati.gkg@pamho.net
                                                    </a>
                                                </p>
                                                <p className="font-17 font-weight-7 mb-0 mail-to mt-2">
                                                    <a
                                                        href="https://live.ipms247.com/booking/book-rooms-srisrikrishnabalaramguesthouse"
                                                        target="_new"
                                                        className="btn btn-primary booking"
                                                    >
                                                        Online Room Booking
                                                    </a>
                                                </p>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    Prabhupada Ashram
                                                </div>
                                                <p className="font-17  mb-0 mail-to">
                                                    <a href="tel:+919557505411">Call: +91 9557505411</a>
                                                </p>
                                                <p className="font-17 mb-0 mail-to">
                                                    <a href="mailto:email4seva@gmail.com">
                                                        Email: email4seva@gmail.com
                                                    </a>
                                                </p>
                                                <p className="font-17 font-weight-7 mb-0 mail-to mt-2">
                                                    <a
                                                        href="https://live.ipms247.com/booking/book-rooms-prabhupadaashramiskcon"
                                                        target="_new"
                                                        className="btn btn-primary booking"
                                                    >
                                                        Online Room Booking
                                                    </a>
                                                </p>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <div className="ico-contact font-16 font-weight-7 d-flex align-items-center mb-3">
                                                    Guest House&nbsp;Annex
                                                </div>
                                                <p className="font-17  mb-0 mail-to">
                                                    <a href="tel:+919997738666">Call: +91 9997738666</a>
                                                </p>
                                                <p className="font-17  mb-0 mail-to">
                                                    <a href="mailto:reservations@mvtindia.com">
                                                        Email: reservations@mvtindia.com
                                                    </a>
                                                </p>
                                                <p className="font-17 font-weight-7 mb-0 mail-to mt-2">
                                                    <a
                                                        href="https://live.ipms247.com/booking/book-rooms-ashrayakanakdhara"
                                                        target="_new"
                                                        className="btn btn-primary booking"
                                                    >
                                                        Online Room Booking
                                                    </a>
                                                </p>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
