import { Link, useNavigate } from "react-router-dom";
import festivalJson from "./festival.json";
const festivalList = festivalJson['festivalList'];

export default function Festival() {
    let navigate = useNavigate();

    const redirectFn = (path) => {
        navigate(path);
    }
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">{festivalJson['title']}</h3>
                        <p>{festivalJson['desc']}</p>
                    </div>
                    <div className="w-100 mt-4  service-img-wraping">
                        <div className="row flex-wrap">
                            {
                                festivalList.map((value, key) => {
                                    return (
                                        <div key={key} className="col-md-3">
                                            <div className="card" onClick={()=>redirectFn(''+value['detailUrl'])}>
                                                <img
                                                    src={value['imgUrl']}
                                                    alt={value['title']}
                                                    className="card-img-top"
                                                />
                                                <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                                    <div className="puja-content">
                                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                                        {value['title']}
                                                    </h5>
                                                    <Link
                                                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                                    >
                                                        {value['btnText']}
                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}