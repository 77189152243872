import _ from "lodash";
import festivalDetail from "./festivalDetail.json";
import { useParams } from "react-router-dom";
const slugWiseDetail = _.keyBy(festivalDetail['festivalDetail'],'slug');

export default function FestivalDetail() {
    const { slug } = useParams();
    const mainDetail = slugWiseDetail[slug];
    return (
        !_.isEmpty(mainDetail)?
        <div className="container-fluid">
            <div className="container">
                <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        {
                            mainDetail['title']!==''?
                            <h3 className="pb-4">{mainDetail['title']}</h3>:''
                        }
                        <div className="row">
                            <div className="col-md-12 font-weight-5 para-txt" dangerouslySetInnerHTML={{__html: _.unescape(mainDetail['bodyHtml'])}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>:''
    )
}