export default function TermsCondition() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                        <div className="d-flex flex-column">
                        <h3 className="pb-4">Terms And Condition</h3>
                        <div className="row">
                            <div className="col-md-12 font-weight-5 para-txt">
                            <div className="disInline">
                                <p className="pb-4">
                                The website www.iskcovrindavan.com (The “SITE”) is fully owned
                                by International Society of Krishna Consciousness (ISKCON),
                                iskcon vrindavan, raman reti, vrindavan-281121.
                                </p>
                                <p className="pb-4">
                                Your use of the Site is governed by the policies, terms, and
                                conditions set forth below. Please read the following
                                information carefully. By using this Site or donating through
                                the site, you indicate your acceptance of, and agreement to be
                                bound by, the terms set forth below. If you do not agree to
                                these terms and conditions, please do not use this Site.
                                </p>
                                <p className="pb-4">
                                These terms and conditions may be changed by ISKCON in the
                                future. It is your responsibility as a user to periodically
                                return to this page to review the terms and conditions for
                                amendments. The amended terms shall take effect automatically
                                the day they are posted on the site. Your continued use of the
                                web site following any amendments will constitute agreement to
                                such amendments. These Terms of Service were last revised on
                                May 30th, 2021.
                                </p>
                                <p className="pb-4">
                                Your continued usage of the site, shall be in acceptance to
                                the terms that may be updated in future with retrospective
                                effect. In case you do not agree to any terms your sole remedy
                                is to stop using the website.
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}