export default function RefundAndCancellationPolicy() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">Refund &amp; Cancellation Policy</h3>
                        <div className="row">
                        <div className="col-md-12 font-weight-5 para-txt">
                            <div className="disInline">
                            <p className="pb-4">
                                ISKCON currently accepts donations through its Online Payment
                                Gateway Facility for various charitable / social purposes.
                                ISKCON also accepts the payment of "booking of our Guest House".
                                Any payment made multiple times due to error, or any other kind
                                of erroneous payment may be brought to the notice of the Site
                                officials and the payment would be refunded if the Site official
                                is satisfied with the cause and/or reason of error by the payee.
                            </p>
                            <p className="pb-4">
                                All donations and/or payment made through the Site, the
                                acceptance of the same is at the sole discretion of the temple
                                management and its trustees. The management reserves its sole
                                rights to accept or cancel any donation and/or payment without
                                assigning any reason thereof.
                            </p>
                            <p className="pb-4">
                                Please note that donations once made cannot be charged back,
                                except in cases of fraudulent use, once investigation is carried
                                out by necessary governmental / Bank authorities and proofs
                                substantiated. In case any donation receipt is issued against
                                the same, that shall stand cancelled, and any income tax benefit
                                so derived shall be null and void.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}