export default function Founder() {
    return (
        <>
            <div className="container-fluid pt-4">
                <div className="container">
                    <div className="w-100">
                    <div
                        id="CulturalCR"
                        className="carousel slide carousel-fade d-md-flex d-none"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                        <div className="carousel-item">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        <div className="carousel-item active">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        </div>
                        <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="prev"
                        >
                        <span
                            className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs-arw" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="next"
                        >
                        <span
                            className="carousel-control-next-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs" />
                        </span>
                        <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">Founder</h3>
                        <div className="row">
                        <div className="col-md-12 font-weight-5 para-txt">
                            <div className="disInline">
                            <p className="pb-4">
                                His Divine Grace A.C. Bhaktivedanta Swami Prabhupada appeared in
                                this world in 1896 in Calcutta, India. He first met his
                                spiritual master, Srila Bhaktisiddhanta Sarasvati Gosvami, in
                                Calcutta in 1922. Bhaktisiddhanta Sarasvati, a prominent
                                religious scholar and the founder of sixty-four Gaudiya Mathas
                                (Vedic institutes), liked this educated young man and convinced
                                him to dedicate his life to teaching Vedic knowledge. Srila
                                Prabhupada became his student and, in 1933, his formally
                                initiated disciple.
                            </p>
                            <p className="pb-4">
                                At their first meeting, in 1922, Srila Bhaktisiddhanta Sarasvati
                                requested Srila Prabhupada to broadcast Vedic knowledge in
                                English. In the years that followed, Srila Prabhupada wrote a
                                commentary on the Bhagavad-gita, assisted the Gaudiya Matha in
                                its work, and, in 1944, started Back to Godhead, an English
                                fortnightly magazine. Single-handedly, Srila Prabhupada edited
                                it, typed the manuscripts, checked the galley proofs, and even
                                distributed the individual copies. The magazine is now being
                                continued by his disciples in the West.
                            </p>
                            <p className="pb-4">
                                In 1950 Srila Prabhupada retired from married life, adopting the
                                vanaprastha (retired) order to devote more time to his studies
                                and writing. He traveled to the holy city of Vrndavana, where he
                                lived in humble circumstances in the historic temple of
                                Radha-Damodara. There he engaged for several years in deep study
                                and writing. He accepted the renounced order of life (sannyasa)
                                in 1959. At Radha-Damodara, Srila Prabhupada began work on his
                                lifes masterpiece: a multivolume commentated translation of the
                                eighteen-thousand-verse Srimad-Bhagavatam (Bhagavata Purana). He
                                also wrote Easy Journey to Other Planets.
                            </p>
                            <p className="pb-4">
                                After publishing three volumes of the Bhagavatam, Srila
                                Prabhupada came to the United States, in September 1965, to
                                fulfill the mission of his spiritual master. Subsequently, His
                                Divine Grace wrote more than fifty volumes of authoritative
                                commentated translations and summary studies of the
                                philosophical and religious classics of India.
                            </p>
                            <p className="pb-4">
                                When he first arrived by freighter in New York City, Srila
                                Prabhupada was practically penniless. Only after almost a year
                                of great difficulty did he establish the International Society
                                for Krishna Consciousness, in July of 1966. Before he passed
                                away on November 14, 1977, he had guided the Society and seen it
                                grow to a worldwide confederation of more than one hundred
                                asramas, schools, temples, institutes, and farm communities.
                            </p>
                            <p className="pb-4">
                                In 1972 His Divine Grace introduced the Vedic system of primary
                                and secondary education in the West by founding the gurukula
                                school in Dallas, Texas. Since then his disciples have
                                established similar schools throughout the United States and the
                                rest of the world.
                            </p>
                            <p className="pb-4">
                                Srila Prabhupada also inspired the construction of several large
                                international cultural centers in India. The center at Sridhama
                                Mayapur, in West Bengal, is the site for a planned spiritual
                                city, an ambitions project for which construction will extend
                                over many years to come. In Vrndavana are the magnificent
                                Krsna-Balarama Temple and International Guesthouse, gurukula
                                school, and Srila Prabhupada Memorial and Museum. There is also
                                a major cultural and educational center in Mumbai. Other centers
                                are planned in a dozen important locations on the Indian
                                subcontinent.
                            </p>
                            <p className="pb-4">Srila Prabhupada on ISKCON Vrindavana</p>
                            <p className="pb-4">
                                Our Vrindavan temple is perhaps our nicest building in the whole
                                movement, and I want that our Vrindavan temple to be the first
                                class temple of India. Already people are saying that it
                                surpasses all temples of the district in beauty, and I think it
                                surasses all temples in India.
                            </p>
                            <p className="pb-4">
                                Mayapur and Vrindavan are places of spiritual inspiration for
                                all devotees of ISKCON. They are the best places in the world to
                                come and chant Hare Krishna and prepare for going back to home,
                                back to Godhead.
                            </p>
                            <p className="pb-4">
                                Vrindavana is the most sacred place within this cosmic universe,
                                and people seeking to achieve spiritual emancipation by entering
                                the kingdom of God may make a home at Vrindavana and become
                                serious students of the six Gosvamis, who were instructed by
                                Lord Sri Caitanya Mahaprabhu.
                            </p>
                            <p className="pb-4">
                                His Divine Grace A. C. Bhaktivedanta Swami Srila Prabhupada,
                                Founder Acharya of the International Society for Krishna
                                Consciousness, inaugurated the Krishna Balaram Mandir in Sri
                                Vrindavana Dhama and installed the most beautiful Deities of Sri
                                Sri Krishna Balaram, Sri Sri Radha Shyamasundar, Lalita Devi and
                                Vishakha Devi and Sri Sri Gaura Nitai on Ram Navami Day, 20
                                April 1975.
                            </p>
                            <p className="pb-4">
                                Srila Prabhupadas most significant contribution, however, is his
                                books. Highly respected by scholars for their authority, depth,
                                and clarity, they are used as textbooks in numerous college
                                courses. His writings have been translated into over fifty
                                languages. The Bhaktivedanta Book Trust, established in 1972 to
                                publish the works of His Divine Grace, has thus become the
                                worlds largest publisher of books in the field of Indian
                                religion and philosophy.
                            </p>
                            <p className="pb-4">
                                In just twelve years, despite his advanced age, Srila Prabhupada
                                circled the globe fourteen times on lecture tours that took him
                                to six continents. In spite of such a vigorous schedule, Srila
                                Prabhupada continued to write prolifically. His writings
                                constitute a veritable library of Vedic philosophy, religion,
                                literature, and culture.
                            </p>
                            <p className="pb-4">
                                Srila Prabhupada spend several years in Vrindavan before going
                                to America. Through his travels and preaching he revealed the
                                glory of Vrindavan-dham to the whole world.
                            </p>
                            <p className="pb-4">Srila Prabhupada Chronology</p>
                            <p className="pb-4">
                                1954 Left family, vanaprastha
                                <br />
                                1956 Vrindavan residence, Vamsi Gopal temple
                                <br />
                                1959 Begins translating Srimad Bhagavatam in Delhi
                                <br />
                                July Moves upstairs Radha-Damodara temple
                                <br />
                                Sept. 17, Takes sannyasa, renounced order of life
                                <br />
                                1960 Fall Publishes first book, paperback Easy Journey to Other
                                Planets
                                <br />
                                1962 July Moves into downstairs rooms at Radha-Damodara
                                <br />
                                Sept. Publishes Volume one, Srimad Bhagavatam in Delhi
                                <br />
                                1963 December Publishes volume two Srimad Bhagavatam
                                <br />
                                1964 December Publishes volume three Srimad Bhagavatam
                                <br />
                                1965 August 13, Leaves Calcutta on Jaladuta bound for America
                                <br />
                                1967 Returns to Radha-Damodara
                                <br />
                                1971 Visits Radha-Damodara and Vrindavan with forty disciples
                                <br />
                                1972 Kartik at Radha-Damodara, delivers famous Nectar of
                                Devotion Lectures
                                <br />
                                1977 Enters the eternal lila of Radha and Krishna in Goloka
                                Vrindavan
                            </p>
                            <p className="pb-4">
                                (from: Mahanidhi Swami: Prabhupada in Radha-Damodara, BBT 2003)
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
            </div>

        </>
    )
}

