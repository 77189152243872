import { Link } from 'react-router-dom';
import blogListJson from '../Blog/blogList.json';
import KirtanLecturesJson from '../KirtanLectures/kirtanLectures.json';
import _ from 'lodash';

import Stories from 'react-insta-stories';
import dailyDarshan from '../DailyDarshan/dailyDarshan.json';
import { useState } from 'react';

export default function Home() {
    const blogList = _.slice(blogListJson['blogList'], 0, 4);
    const kirtanLecturesList = _.slice(KirtanLecturesJson['kirtanLecturesList'], 0, 4);
    const dailyDarshanList = dailyDarshan['dailyDarshanList'];
    const [storyList, setStoryList] = useState([dailyDarshanList[0]['listImg']]);
    // let navigate = useNavigate();

    const redirectFn = (path) => {
        window.open(path, '_blank');
        // navigate(path);
    }
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div
                        id="carouselFirst"
                        className="carousel slide pt-4 carousal-element"
                        data-bs-ride="carousel"
                        data-bs-interval="10000">
                        <div className="carousel-indicators d-md-flex d-none">
                            <button
                                type="button"
                                data-bs-target="#carouselFirst"
                                data-bs-slide-to={0}
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"
                            />
                            <button
                                type="button"
                                data-bs-target="#carouselFirst"
                                data-bs-slide-to={1}
                                className=""
                                aria-current=""
                                aria-label="Slide 2"
                            />
                            <button
                                type="button"
                                data-bs-target="#carouselFirst"
                                data-bs-slide-to={2}
                                className=""
                                aria-current=""
                                aria-label="Slide 3"
                            />
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row align-items-center">
                                    <div className="col-md-5 pl-70 order-md-0 order-3">
                                        <h3 className="janmashatmi">Sri Krishna Janmashtami</h3>
                                        <h4 className="text-uppercase">
                                            An Opportunity to Serve Sri Krishna
                                        </h4>
                                        <p>
                                            Any service, charity given on the special days like Sri Krishna
                                            Janmashtami bears eternal credit for the performer and moreover
                                            any service done in Sri Dham Vrindavan bears thousand of times
                                            multiple benefit.
                                        </p>
                                        <Link
                                            to="/donate"
                                            className="btn btn-sm w-50 btn-primary d-flex align-items-center justify-content-center mb-2"
                                        >
                                            Donate Now
                                        </Link>
                                    </div>
                                    <div className="col-md-4 order-md-0 order-1">
                                        <img
                                            src="/images/banner/janmashtami.jpg"
                                            alt=""
                                            className="radhekris mundiabg"
                                        />
                                    </div>
                                    <div className="col-md-3 order-md-0 order-2 py-md-0 py-4 grid-block">
                                        <div className="d-flex flex-column gap-4">
                                            <ul className="d-flex flex-column gap-4 me-3">
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="/images/availability.svg"
                                                            alt=""
                                                            className="avla"
                                                        />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Availability</h6>
                                                        <h5>365 Days Live Darshan</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="/images/community.svg"
                                                            alt=""
                                                            className="avla"
                                                        />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Community</h6>
                                                        <h5>Visit Iskcon Varachha</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img src="/images/donate.svg" alt="" className="avla" />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Donate</h6>
                                                        <h5>Prasad Distribution </h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row align-items-center">
                                    <div className="col-md-5 pl-70 order-md-0 order-3">
                                        <h3 className="janmashatmi">Srila Prabhupada Appearance</h3>
                                        <h4 className="text-uppercase">
                                            August 27, 2024
                                        </h4>
                                        <p>
                                            Srila Prabhupada's life serves as a beacon of inspiration and hope to people worldwide. His dedication to spreading the teachings of Lord Krishna and his unwavering faith in his spiritual master's instructions are exemplary.
                                        </p>
                                        {/* <button
                                            type="button"
                                            className="btn btn-primary d-flex align-items-center justify-content-center mb-2"
                                        >
                                            Read More
                                        </button> */}
                                    </div>
                                    <div className="col-md-4 order-md-0 order-1">
                                        <img
                                            src="/images/banner/prabhupad-banner.png"
                                            alt=""
                                            className="radhekris mundiabg"
                                        />
                                    </div>
                                    <div className="col-md-3 order-md-0 order-2 py-md-0 py-4 grid-block">
                                        <div className="d-flex flex-column gap-4">
                                            <ul className="d-flex flex-column gap-4 me-3">
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img src="/images/availability.svg" alt="" className="avla" />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Availability</h6>
                                                        <h5>365 Days Live Darshan</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img src="/images/kalash.png" alt="" className="avla" />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Darshan</h6>
                                                        <h5>Kirtan &amp; Darshan</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img src="/images/donate.svg" alt="" className="avla" />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Donate</h6>
                                                        <h5>Prasad Distribution</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row align-items-center">
                                    <div className="col-md-5 pl-70 order-md-0 order-3">
                                        <h3 className="janmashatmi">H.H. Gopal Krishna Goswami Maharaja</h3>
                                        <h4 className="text-uppercase">
                                            Appearance August 14, 2024
                                        </h4>
                                        <p>
                                            Gopal Krishna Goswami has served as personal secretary to Srila Prabhupada at various times. After graduating from Delhi University in 1964, Gopal Krishna Goswami studied business management in France under a French government scholarship. He also studied for some time at Sorbonne University in Paris.
                                        </p>
                                        {/* <button
                                            type="button"
                                            className="btn btn-primary d-flex align-items-center justify-content-center mb-2"
                                        >
                                            Read More
                                        </button> */}
                                    </div>
                                    <div className="col-md-4 order-md-0 order-1">
                                        <img
                                            src="/images/banner/gopalprabhu-banner.png"
                                            alt=""
                                            className="radhekris mundiabg"
                                        />
                                    </div>
                                    <div className="col-md-3 order-md-0 order-2 py-md-0 py-4 grid-block">
                                        <div className="d-flex flex-column gap-4">
                                            <ul className="d-flex flex-column gap-4 me-3">
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="/images/availability.svg"
                                                            alt=""
                                                            className="avla"
                                                        />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Availability</h6>
                                                        <h5>365 Days Live Darshan</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="/images/community.svg"
                                                            alt=""
                                                            className="avla"
                                                        />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Community</h6>
                                                        <h5>Visit Iskcon Varachha</h5>
                                                    </div>
                                                </li>
                                                <li className="d-flex  align-items-center">
                                                    <div className="cr-icon d-flex align-items-center justify-content-center">
                                                        <img src="/images/donate.svg" alt="" className="avla" />
                                                    </div>
                                                    <div className="cr-text ms-3">
                                                        <h6 className="mb-1">Donate</h6>
                                                        <h5>Prasad Distribution </h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev d-md-flex d-none"
                            type="button"
                            data-bs-target="#carouselFirst"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
                                aria-hidden="true"
                            >
                                <img src="/images/arrow-crsl.svg" alt="" className="crs-arw" />
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next d-md-flex d-none"
                            type="button"
                            data-bs-target="#carouselFirst"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon d-flex align-items-center justify-content-center"
                                aria-hidden="true"
                            >
                                <img src="/images/arrow-crsl.svg" alt="" className="crs" />
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="pb-4">Explore Vrindavan</h3>
                        <a href="/visit-vrindavan" className="view-all-button">
                        View all
                        </a>
                    </div>
                    <section
                        id="serviceSlider"
                        className="variable slider slick-initialized slick-slider slick-dotted"
                    >
                        <button
                        className="slick-prev slick-arrow"
                        aria-label="Previous"
                        type="button"
                        style={{}}
                        >
                        Previous
                        </button>
                        <div className="slick-list draggable">
                        <div
                            className="slick-track"
                            style={{
                            opacity: 1,
                            width: 30000,
                            transform: "translate3d(-324px, 0px, 0px)"
                            }}
                        >
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-3364b14c6cb0313.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Visitor Facility</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-current slick-active"
                            data-slick-index={0}
                            aria-hidden="false"
                            role="tabpanel"
                            id="slick-slide00"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconvrindavan.s3.ap-south-1.amazonaws.com/page_category/vaishnana-calendar_4464f0361d4e76c.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Vaishnana Calendar</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={1}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide01"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-8864b14c6c91e16.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Guest House Booking</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={2}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide02"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-3364b14dff5de8c.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Dining Options</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={3}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide03"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-7364b14c6d139ed.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Holy Dham Etiquette</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={4}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide04"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-9764b14c6cd312c.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Safety Tips</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={5}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide05"
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-3364b14c6cb0313.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Visitor Facility</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={6}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconvrindavan.s3.ap-south-1.amazonaws.com/page_category/vaishnana-calendar_4464f0361d4e76c.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Vaishnana Calendar</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={7}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-8864b14c6c91e16.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Guest House Booking</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={8}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-3364b14dff5de8c.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Dining Options</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={9}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-7364b14c6d139ed.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Holy Dham Etiquette</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={10}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-9764b14c6cd312c.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Safety Tips</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={11}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="col-md-2 cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="card w-100">
                                    <img
                                    src="https://iskconstatic.s3.ap-south-1.amazonaws.com/image-3364b14c6cb0313.jpeg"
                                    className="card-img-top"
                                    alt="..."
                                    />
                                    <div className="card-body text-center">
                                    <h5 className="card-title">Visitor Facility</h5>
                                    <p className="card-text mb-0">Explore Vrindavan</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <button
                        className="slick-next slick-arrow"
                        aria-label="Next"
                        type="button"
                        style={{}}
                        >
                        Next
                        </button>
                        <ul className="slick-dots" style={{ display: "block" }} role="tablist">
                        <li className="slick-active" role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control00"
                            aria-controls="slick-slide00"
                            aria-label="1 of 6"
                            tabIndex={0}
                            aria-selected="true"
                            >
                            1
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control01"
                            aria-controls="slick-slide01"
                            aria-label="2 of 6"
                            tabIndex={-1}
                            >
                            2
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control02"
                            aria-controls="slick-slide02"
                            aria-label="3 of 6"
                            tabIndex={-1}
                            >
                            3
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control03"
                            aria-controls="slick-slide03"
                            aria-label="4 of 6"
                            tabIndex={-1}
                            >
                            4
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control04"
                            aria-controls="slick-slide04"
                            aria-label="5 of 6"
                            tabIndex={-1}
                            >
                            5
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control05"
                            aria-controls="slick-slide05"
                            aria-label="6 of 6"
                            tabIndex={-1}
                            >
                            6
                            </button>
                        </li>
                        </ul>
                    </section>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5">
                    <div className="d-flex align-items-center justify-content-between pb-0">
                        <div>
                            <h3 className="pb-2 mb-0">Festivals & Daily darshan</h3>
                            <p>Rath yatra, Janmashatmi and many more</p>
                            </div>
                            <Link to="/daily-darshan-gallery" className="view-all-button">
                            View all
                            </Link>
                        </div>
                        <div className="w-100 service-area">
                        <div className="row mt-4">
                            {
                                dailyDarshanList.map((value, key) => {
                                    return (
                                        <div key={key} className="col-md-3">
                                            <div className="puja-bx cursor-pointer" data-bs-toggle="modal" data-bs-target="#darshanModal" onClick={()=>setStoryList(value['listImg'])}>
                                                <div className="w-100 position-relative">
                                                <img
                                                    src={value['imgUrl']}
                                                    alt=""
                                                    className="d-block w-100 offer-p dargal"
                                                />
                                                </div>
                                                <div className="px-3 py-4 w-100 bg-white rounded-bottom">
                                                <div className="puja-content">
                                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine" />
                                                    <div className="d-flex align-items-center font-12 font-weight-6">
                                                    <img src="/images/time.svg" className="me-2" alt="" />{" "}
                                                    <span className="video-date">{value['postDate']}</span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area pb-5">
                    <div className="d-flex align-items-center justify-content-between pb-0">
                        <div>
                        <h3 className="pb-2 mb-0">Festivals & Daily darshan</h3>
                        <p>Rath yatra, Janmashatmi and many more</p>
                        </div>
                        <Link to="/daily-darshan-gallery" className="view-all-button">
                        View all
                        </Link>
                    </div>
                    <section
                        id="serviceSlider2"
                        className="variable slider slick-initialized slick-slider slick-dotted"
                    >
                        <button
                        className="slick-prev slick-arrow"
                        aria-label="Previous"
                        type="button"
                        style={{}}
                        >
                        Previous
                        </button>
                        <div className="slick-list draggable">
                        <div
                            className="slick-track"
                            style={{
                            opacity: 1,
                            width: 45000,
                            transform: "translate3d(-241px, 0px, 0px)"
                            }}
                        >
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={-1}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service5.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Srila Prabhupad Samadhi</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-current slick-active"
                            data-slick-index={0}
                            aria-hidden="false"
                            role="tabpanel"
                            id="slick-slide10"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/cow.png"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Simple Living, High Thinking</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={1}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide11"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/audio.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Srila Prabhupada Audiobooks</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={2}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide12"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Life Membership</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={3}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide13"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service1.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Community Service Centre</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={4}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide14"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service2.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Gomata Products</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={5}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide15"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/books.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Buy Books Online</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={6}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide16"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service3.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">ISKCON Youth Forum</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={7}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide17"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service4.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Govinda Restaurant</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide"
                            data-slick-index={8}
                            aria-hidden="true"
                            tabIndex={-1}
                            role="tabpanel"
                            id="slick-slide18"
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service5.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Srila Prabhupad Samadhi</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={9}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/cow.png"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Simple Living, High Thinking</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={10}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/audio.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Srila Prabhupada Audiobooks</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={11}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Life Membership</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={12}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service1.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Community Service Centre</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={13}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service2.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Gomata Products</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={14}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/books.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Buy Books Online</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={15}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service3.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">ISKCON Youth Forum</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={16}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service4.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Govinda Restaurant</p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div
                            className="slick-slide slick-cloned"
                            data-slick-index={17}
                            id=""
                            aria-hidden="true"
                            tabIndex={-1}
                            >
                            <div>
                                <div
                                className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3  cursor-pt"
                                style={{ width: "100%", display: "inline-block" }}
                                >
                                <div className="d-flex flex-column gap-4 service-wrap align-items-center mb-md-0 mb-3">
                                    <div className="serviceBx d-flex align-items-center justify-content-center">
                                    <img
                                        src="/images/service5.svg"
                                        alt=""
                                        className="serviceimg"
                                    />
                                    </div>
                                    <p className="mb-0">Srila Prabhupad Samadhi</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <button
                        className="slick-next slick-arrow"
                        aria-label="Next"
                        type="button"
                        style={{}}
                        >
                        Next
                        </button>
                        <ul className="slick-dots" style={{ display: "block" }} role="tablist">
                        <li className="slick-active" role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control10"
                            aria-controls="slick-slide10"
                            aria-label="1 of 9"
                            tabIndex={0}
                            aria-selected="true"
                            >
                            1
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control11"
                            aria-controls="slick-slide11"
                            aria-label="2 of 9"
                            tabIndex={-1}
                            >
                            2
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control12"
                            aria-controls="slick-slide12"
                            aria-label="3 of 9"
                            tabIndex={-1}
                            >
                            3
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control13"
                            aria-controls="slick-slide13"
                            aria-label="4 of 9"
                            tabIndex={-1}
                            >
                            4
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control14"
                            aria-controls="slick-slide14"
                            aria-label="5 of 9"
                            tabIndex={-1}
                            >
                            5
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control15"
                            aria-controls="slick-slide15"
                            aria-label="6 of 9"
                            tabIndex={-1}
                            >
                            6
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control16"
                            aria-controls="slick-slide16"
                            aria-label="7 of 9"
                            tabIndex={-1}
                            >
                            7
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control17"
                            aria-controls="slick-slide17"
                            aria-label="8 of 9"
                            tabIndex={-1}
                            >
                            8
                            </button>
                        </li>
                        <li role="presentation">
                            <button
                            type="button"
                            role="tab"
                            id="slick-slide-control18"
                            aria-controls="slick-slide18"
                            aria-label="9 of 9"
                            tabIndex={-1}
                            >
                            9
                            </button>
                        </li>
                        </ul>
                    </section>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 body-text donate">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <h3 className="pb-2 mb-0">Kirtans &amp; Lectures</h3>
                            </div>
                            <Link to="/kirtan-lectures" className="view-all-button">View all</Link>
                        </div>
                        <div className="w-100 service-area pb-5">
                            <div className="row mt-4">
                                {
                                    kirtanLecturesList.map((value, key) => {
                                        return (
                                            // <div className='slick-slide'>
                                            <div className="col-md-3 mb-4" key={key}>
                                                <div className="puja-bx cursor-pointer" onClick={()=>redirectFn(value['linkUrl'])}>
                                                    <div className="w-100 position-relative">
                                                        <img src={value['thumbUrl']} alt={value['title']} className="d-block w-100 offer-p"/>
                                                        <img src="/images/youtube.png" alt="" className="position-absolute youtube-icon"/>
                                                    </div>
                                                    <div className="px-3 py-4 w-100 bg-white rounded-bottom">
                                                        <div className="puja-content">
                                                            <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine">
                                                                {value['title']}
                                                            </h5>
                                                            <div className="d-flex align-items-center font-12 font-weight-6">
                                                            <img src="/images/time.svg" className="me-2" alt="" />
                                                            <span className="video-date">{value['postDate']}</span>
                                                            </div>
                                                        </div>
                                                        <Link className="btn btn-primary mt-3 w-100 d-flex align-items-center justify-content-center">View Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            // </div>
                                        )
                                    })
                                }
                                {/* <div className="col-md-3">
                                    <div className="puja-bx card">
                                        <div className="w-100 position-relative">
                                            <div className="donate-bx-img  date-donate">
                                            <span>Aug 26, 2024</span>
                                            </div>
                                            <img
                                            src="https://iskcon-vrindavan.s3.ap-south-1.amazonaws.com/page_category/page-category-_9066a09788d86f9.jpeg"
                                            alt="Sri Krishna Janmashtami Seva"
                                            className="d-block w-100 offer-p"
                                            />
                                        </div>
                                        <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                            <div className="puja-content">
                                            <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine  text-center">
                                                Sri Krishna Janmashtami Seva
                                            </h5>
                                            <Link href="/donto" className="btn btn-primary w-100 d-flex align-items-center -content-center"
                                            >
                                                Donate NLinkw
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 body-text donate">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                        <h3 className="pb-2 mb-0">Donate us!</h3>
                        <p>Your small contribution makes a difference</p>
                        </div>
                        <Link to="/donate" className="view-all-button">
                        View all
                        </Link>
                    </div>
                    <div className="w-100 service-area pb-5">
                        <div className="row mt-4">
                        <div className="col-md-3">
                            <div className="puja-bx card">
                            <div className="w-100 position-relative">
                                {/* <div className="donate-bx-img  date-donate">
                                <span>Aug 26, 2024</span>
                                </div> */}
                                <img
                                src="images/donate-us/d-1.png"
                                alt="Sri Krishna Janmashtami Seva"
                                className="d-block w-100 offer-p"
                                />
                            </div>
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine  text-center">
                                    Sri Krishna Janmashtami Seva
                                </h5>
                                <Link to="/donate" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                                    Donate Now
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="puja-bx card">
                            <div className="w-100 position-relative">
                                {/* <div className="donate-bx-img  date-donate">
                                <span>Aug 26, 2024</span>
                                </div> */}
                                <img
                                src="images/donate-us/d-2.png"
                                alt="Janmashtami Kalash Seva"
                                className="d-block w-100 offer-p"
                                />
                            </div>
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine  text-center">
                                    Janmashtami Kalash Seva
                                </h5>
                                <Link to="/donate" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                                    Donate Now
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="puja-bx card">
                            <div className="w-100 position-relative">
                                {/* <div className="donate-bx-img  date-donate">
                                <span>Aug 26, 2024</span>
                                </div> */}
                                <img
                                src="images/donate-us/d-3.png"
                                alt="Janmashtami Gau/Cow Seva"
                                className="d-block w-100 offer-p"
                                />
                            </div>
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine  text-center">
                                    Janmashtami Gau/Cow Seva
                                </h5>
                                <Link to="/donate" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                                    Donate Now
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="puja-bx card">
                            <div className="w-100 position-relative">
                                {/* <div className="donate-bx-img  date-donate">
                                <span>Aug 30, 2024</span>
                                </div> */}
                                <img
                                src="images/donate-us/d-4.png"
                                alt="Annada Ekadasi"
                                className="d-block w-100 offer-p"
                                />
                            </div>
                            <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                <div className="puja-content">
                                <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine  text-center">
                                    Annada Ekadasi
                                </h5>
                                <Link to="/donate" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                                    Donate Now
                                </Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 latest-news pb-5 body-text donate">
                        <div className=" d-flex align-items-center justify-content-between">
                            <div>
                                <h3 className="pb-2 mb-0">Latest Blogs</h3>
                            </div>
                            <Link to="/blog" className="view-all-button">View all</Link>
                        </div>
                        <div className="row mt-4">
                            {
                                blogList.map((value, key) => {
                                    let pDateArray = _.split(value['postDate'],', ');
                                    let postDateArray = _.split(pDateArray[0],' ');
                                    return (
                                        <div className="col-md-3 mb-md-0 mb-3" key={key}>
                                            <div className="card">
                                                <img src={value['imgUrl']} className="card-img-top" alt="..." />
                                                <div className="card-body position-relative">
                                                    <div className="news-date position-absolute d-flex align-items-center justify-content-center flex-column">
                                                        <span>{postDateArray[1]}</span>
                                                        <span>{postDateArray[0]}</span>
                                                    </div>
                                                    <h5 className="card-title pt-5">{value['title']}</h5>
                                                    <p className="card-text">
                                                        {value['desc']}
                                                    </p>
                                                    <div className="d-flex align-items-center justify-content-center w-100 mt-4">
                                                        <Link to={'blog/'+value['slug']} className="btn btn-primary">
                                                        Read More
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="modal fade" id="storyModal" tabindex="-1" aria-labelledby="storyModalLabel" aria-hidden="true"> */}
            {
                storyList.length > 0 ?
                <div className="modal fade show" id="darshanModal" tabIndex="-1" aria-labelledby="LoaderModalLabel" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content noti-modal">
                            <div className="modal-body">
                                <div className='container-fluid'>
                                    <div className='container center-flex'>
                                        <Stories
                                            loop
                                            keyboardNavigation
                                            stories={storyList}
                                            defaultInterval={3000}
                                            // width={432}
                                            // height={768}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>:''
            }
        </>
    )
}
