export default function Social() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">Our Social Activities</h3>
                        <p>
                        Join the ISKCON Varachha social community and ignite your spirit! Follow us on Facebook, Twitter, Instagram, and YouTube to stay connected with a community dedicated to spiritual growth and motivation. Engage with enriching content, share your journey, and connect with others on the path of devotion and self-improvement. Whether you're seeking daily inspiration or deep spiritual insights, our community is here to support you. Let's inspire each other and spread positivity together!
                        </p>
                    </div>
                    <div className="w-100 mt-4  service-img-wraping">
                        <div className="row flex-wrap">
                            <div className="col-md-3">
                                <div className="card">
                                <img
                                    src="images/social/social-facebook.png"
                                    alt="Facebook"
                                    className="card-img-top"
                                />
                                <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                    <div className="puja-content">
                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                        Facebook
                                    </h5>
                                    <a
                                        href="https://www.facebook.com/iskconvarachha/"
                                        target="_blank"
                                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                    >
                                        Join Now
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                <img
                                    src="images/social/social-twitter.png"
                                    alt="Twitter"
                                    className="card-img-top"
                                />
                                <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                    <div className="puja-content">
                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                        Twitter
                                    </h5>
                                    <a
                                        href="https://twitter.com/iskconvarachha"
                                        target="_blank"
                                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                    >
                                        Join Now
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                <img
                                    src="images/social/social-insta.png"
                                    alt="Instagram"
                                    className="card-img-top"
                                />
                                <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                    <div className="puja-content">
                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                    instagram
                                    </h5>
                                    <a
                                        href="https://www.instagram.com/iskconvarachha"
                                        target="_blank"
                                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                    >
                                        Join Now
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                <img
                                    src="images/social/social-youtube.png"
                                    alt="Youtube"
                                    className="card-img-top"
                                />
                                <div className="px-3 py-4 w-100 bg-white border-bottom-corner">
                                    <div className="puja-content">
                                    <h5 className="font-14 font-weight-8 mb-3 primary-font sinleLine text-center">
                                        Youtube
                                    </h5>
                                    <a
                                        href="https://www.youtube.com/c/murtimandasgkg"
                                        target="_blank"
                                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                    >
                                        Join Now
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
