export default function Philosophy() {
    return (
        <>
            <div className="container-fluid pt-4">
                <div className="container">
                    <div className="w-100">
                    <div
                        id="CulturalCR"
                        className="carousel slide carousel-fade d-md-flex d-none"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                        <div className="carousel-item">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        <div className="carousel-item active">
                            <img
                            src="/images/service/service-banner-2.jpg"
                            alt=""
                            className="d-block w-100 offer-p"
                            />
                        </div>
                        </div>
                        <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="prev"
                        >
                        <span
                            className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs-arw" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#CulturalCR"
                        data-bs-slide="next"
                        >
                        <span
                            className="carousel-control-next-icon d-flex align-items-center justify-content-center"
                            aria-hidden="true"
                        >
                            <img src="/images/arrow-crsl.svg" alt="" className="crs" />
                        </span>
                        <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="w-100 service-area slide-silk-2 py-5 body-text">
                    <div className="d-flex flex-column">
                        <h3 className="pb-4">Philosophy</h3>
                        <div className="row">
                        <div className="col-md-12 font-weight-5 para-txt">
                            <div className="disInline">
                            <p className="pb-4">
                                Probably you have already seen members of the Hare Krsna
                                movement singing and dancing in the streets or you have been
                                asked for donation for a book or a magazine. You could also see
                                us in TV or read about us in newspapers. How many times have you
                                asked yourselves: Who are they? Why do they dress in this way?
                                What do they believe in? Why do they always sing? Who is Krsna?
                            </p>
                            <p className="pb-4">
                                To enable you to better understand us, we want to show you the
                                Hare Krsna movement in detail and tell you who we are. You will
                                be probably surprised by learning that to become a part of the
                                Hare Krsna movement one does not need to wear traditional dress
                                or change ones hairstyle or religion. You will discover that
                                Krsna consciousness is much more than religion. It is a
                                spiritual, universal, time-proven process enabling to achieve
                                inner happiness, satisfaction and higher consciousness.
                            </p>
                            <p className="pb-4">
                                Hare Krsna movement makes spiritual reality and culture derived
                                from it known to the widest public. This makes it unique. When
                                you speak with a devotee from the Hare Krsna movement, eat
                                spiritual food served in our restaurants, take part in the
                                Sunday festival in a temple, read any of our books or chant Hare
                                Krsna at least once, your consciousness will change. And this
                                spiritual change of consciousness is the key to improvement of
                                the quality of life on this planet.
                            </p>
                            <p className="pb-4">Michael Grant (Mukunda Goswami)</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

