// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout.js';
import Home from './components/Home';
import Iskon from './components/Iskon';
import AboutUs from './components/AboutUs';
import History from './components/History';
import Social from './components/Social';
import DailyDarshan from './components/DailyDarshan';
import KirtanLectures from './components/KirtanLectures';
import Founder from './components/Founder';
import WhyKrishnaConsciousMovement from './components/WhyKrishnaConsciousMovement';
import Philosophy from './components/Philosophy';
import Donate from './components/Donate';
import Blog from './components/Blog';
import BlogDetail from './components/Blog/blogDetail.js';
import TermsCondition from './components/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundAndCancellationPolicy from './components/RefundAndCancellationPolicy';
import Festival from './components/Festival';
import FestivalDetail from './components/Festival/festivalDetail.js';
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='home' element={<Iskon />} />
            <Route path='home/about-us' element={<AboutUs />} />
            <Route path='home/the-history' element={<History />} />
            <Route path='home/founder' element={<Founder />} />
            <Route path='home/why-krishna-conscious-movement' element={<WhyKrishnaConsciousMovement />} />
            <Route path='home/philosophy' element={<Philosophy />} />
            <Route path='Social' element={<Social />} />
            <Route path='daily-darshan-gallery' element={<DailyDarshan />} />
            <Route path='kirtan-lectures' element={<KirtanLectures />} />
            <Route path='festival' element={<Festival />} />
            <Route path='festival/:slug' element={<FestivalDetail />} />
            <Route path='donate' element={<Donate />} />
            <Route path='blog' element={<Blog />} />
            <Route path='blog/:slug' element={<BlogDetail />} />
            <Route path='terms-policy/terms-and-condition' element={<TermsCondition />} />
            <Route path='terms-policy/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='terms-policy/refund-and-cancellation-policy' element={<RefundAndCancellationPolicy />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Routes>
      </Router>
        {/* <Header />
        <TermsCondition />
        <Footer /> */}
    </>
  );
}

export default App;
