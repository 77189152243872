import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
    const location = useLocation();
    const allowHoverMenu = ['/','/home','/social','/festival','/visit-vrindavan','/donate','/blog'];

    let navigate = useNavigate();
    const festivalCloseRef = useRef(null);
    const moreCloseRef = useRef(null);

    const redirectFn = (path) => {
        navigate(path);
    }

    const redirectNewTab = (url) => {
        window.open(url, '_blank');
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    },[location.pathname])

    const hideModal = (type) => {
        if(type==='festival'){
            festivalCloseRef.current?.click();
        }
        else{
            moreCloseRef.current?.click();
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <header className="py-4">
                        <div className="d-flex flex-column flex-md-row position-relative">
                            <div className="d-flex flex-grow-1 align-items-center cursor-pt">
                                <img onClick={()=>redirectFn('/')}
                                    src="/images/logo-iskcon.svg"
                                    alt=""
                                    className="logo-iskon me-3"
                                />
                                <span onClick={()=>redirectFn('/')} className="logo-text font-weight-8 text-uppercase">
                                    ISKCON <br />
                                    VARACHHA
                                </span>
                            </div>
                            <div className="d-flex mobile-absolute  mobile-cls">
                                <ul className="d-flex gap-md-4 gap-2 wrapActive">
                                    <li>
                                        <div className="bg-white temple-open d-flex align-items-center">
                                            <span className="d-flex align-items-center">
                                                <img src="/images/closed.svg" alt="" className="tem me-2" />
                                                Closed
                                            </span>
                                            <span className="mx-2">
                                                <img src="/images/moon.gif" alt="" className="templ-loder" />
                                            </span>
                                            <span className="d-flex align-items-center">20:15 - 04:10</span>
                                        </div>
                                    </li>
                                    <li className="dropdown app-dwnld temple-dropdown backdroClk">
                                        <div
                                            className="bg-white schedule-open d-flex align-items-center dropdown-toggle after-remove me-2"
                                            id="dropdownTemple"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="d-md-inline-block">
                                                <span className="coupon ">
                                                    <img src="/images/schedule.png" alt="" className="coupon" />
                                                </span>
                                                <span className="d-none d-md-inline-block">Schedule</span>
                                            </span>
                                        </div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownTemple"
                                        >
                                            <span className="polygon">
                                                <img src="/images/polygon.svg" alt="" className="polygon" />
                                            </span>
                                            <div className="drop-bx">
                                                <li className="d-flex flex-column">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="wh-sp-norm">
                                                            <h2 className="font-weight-7">
                                                                ISKCON Varachha Temple timings
                                                            </h2>
                                                            <p className="font-weight-6 mb-3">
                                                                
                                                                Closed from 13:00 - 16:00 and 20:15 - 04.10
                                                            </p>
                                                        </div>
                                                        <span className="close-menu d-flex align-items-center justify-content-center d-md-none temp-close">
                                                            <img
                                                                src="/images/nav/close-menu.svg"
                                                                alt=""
                                                                className="close-menu-wrap"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="d-flex gap-md-5 gap-4 mt-4 flex-column flex-md-row max-h-500px">
                                                        <div className="d-flex flex-column">
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        04:30 - Mangal Arti
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">मंगल आरती</p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        07:15 - Sringar Arti
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">श्रृंगार आरती</p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        07:30 - Guru Puja
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">गुरु पूजा</p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        08:00 - Srimad Bhagvat Pravachan
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">
                                                                        श्रीमद्भागवत प्रवचन
                                                                    </p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        12:00 - Raj Bhoga
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">राज भोग</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        16:00 - Utthapana
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">उत्थापना</p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        18:00 - Bhagvat Gita Pravachan
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">
                                                                        भगवत गीता प्रवचन
                                                                    </p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        18:30 - Sandhya Arti
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">संध्या आरती</p>
                                                                </div>
                                                            </a>
                                                            <a className="d-flex align-items-center">
                                                                <div className="temple-open-icon me-3 d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        src="/images/puja-icon.png"
                                                                        alt=""
                                                                        className="puja-icon"
                                                                    />
                                                                </div>
                                                                <div className="temple-content">
                                                                    <h6 className="font-16 font-weight-8 mb-1">
                                                                        20:00 - Shayana Arati
                                                                    </h6>
                                                                    <p className="font-weight-5 mb-0">शयन आरती</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="header-right-element mobile-cls">
                                <ul className="d-flex gap-md-4 gap-2 flex-md-row">
                                    <li className="d-none">
                                        <div className="">
                                            <div className="search-toogle d-flex align-items-center justify-content-center bg-white main-shadow">
                                                <input
                                                    type="text"
                                                    placeholder="Search our services.."
                                                    className="form-control searchinput"
                                                />
                                                <img src="/images/search.svg" alt="" className="search-inp" />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown app-dwnld d-md-none d-flex" onClick={()=> redirectNewTab('https://www.google.com/maps/place/ISKCON+Varachha/@21.2056988,72.8536897,258m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!2sISKCON+Varachha!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t!3m5!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t?hl=en&entry=ttu')}>
                                        <div className="d-flex align-items-center justify-content-center search-item bg-white main-shadow">
                                            <img src="/images/navigation.svg" alt="" className="search" />
                                        </div>
                                    </li>
                                    {/* <li className="dropdown app-dwnld">
                                        <div className="notify-icon position-absolute" />
                                        <div className="d-flex align-items-center justify-content-center main-shadow search-item bg-white cursor-pt">
                                            <img src="/images/notification.svg" alt="" className="search" />
                                        </div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton4"
                                        >
                                            <span className="polygon">
                                                <img src="/images/polygon.svg" alt="" className="polygon" />
                                            </span>
                                            <div className="drop-bx px-0 py-4">
                                                <li className="d-flex flex-column">
                                                    <div className="w-100 d-flex justify-content-between align-items-center px-4">
                                                        <h3>Notification</h3>
                                                        <span
                                                            className="close-menu d-flex align-items-center justify-content-center d-md-none"
                                                            data-bs-toggle="dropdown"
                                                        >
                                                            <img
                                                                src="/images/nav/close-menu.svg"
                                                                alt=""
                                                                className="close-menu-wrap"
                                                            />
                                                        </span>
                                                    </div>
                                                    <p className="pdate px-4">Today</p>
                                                    <div className="notification-wrap dropmenuHeight">
                                                        <div className="d-flex flex-column px-4 py-3 selected notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">&nbsp;</p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        Daily Announcements
                                                                    </h4>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        Today, tomorrow and day after, we will be having a
                                                                        lecture on Srila Rupa Goswami in preparation for
                                                                        the celebration of Srila Rupa Goswami's
                                                                        disappearance day which will be coming on 17th of
                                                                        this month. So, this evening, tomorrow evening and
                                                                        day after tomorrow evening, instead of Bhagavad
                                                                        Gita class, we will have a lecture on Srila Rupa
                                                                        Goswami in Hindi and in English.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        ISKCON Vrindavan is organising a braj darshan tour
                                                                        going to Neemgaon this morning leaving at 6:00 AM
                                                                        at chitrakoot ashram and you will return by 11:00
                                                                        AM. So, please assemble if you wish to go in front
                                                                        of the Chitrakoot ashram before 6:00 AM.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        For the pleasure of Their Lordships, the Guru
                                                                        Parampara and the assembled Vaisnavas, yesterday
                                                                        devotees in Krishna Balaram Mandir distributed
                                                                        below transcendental books. International bookshop
                                                                        distributed 140 transcendental books Matchless
                                                                        Gift shop distributed 309 transcendental books
                                                                        Srila Prabhupada's House 238 transcendental books
                                                                        BBT office distributed 1366 transcendental books
                                                                        Devotees distributed 330 transcendental books
                                                                        during Japa Session Devotees in Srila Prabhupada's
                                                                        Samadhi Mandir distributed 35 transcendental books
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        Grand total of 2418 transcendental books were
                                                                        distributed.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        520 visitors chanted one round Hare Krishna maha
                                                                        mantra in Srila Prabhupada's Samadhi mandir, &amp;
                                                                        56 visitors during the morning Japa session.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        Namahatta department engaged 260 brajabasis to
                                                                        chant one round Hare Krishna maha mantra at Atas
                                                                        Bari village.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        22610 leaf cups of kitchari prasadam were
                                                                        distributed to our visiting pilgrims yesterday.
                                                                    </p>
                                                                    <p className="mb-2 notify-paragraph">
                                                                        Krishna Balaram Mandir welcomes everyone to Sri
                                                                        Vrindavan Dham and our Iskcon Mangal Arati
                                                                        program. Today we especially welcome back His
                                                                        Holiness Prabhodananda Swami Maharaj, His Holiness
                                                                        Bhakti Kevala Gopendra Krishna Swami Maharaj, His
                                                                        Grace Krishnanand Prabhu and His Grace Dasa Avatar
                                                                        Prabhu.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;8 AM-9 AM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        English Srimad Bhagwatm
                                                                    </h4>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/profile.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        His Grace Advaita Krishna Prabhu(Mauritius)
                                                                    </div>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/nav/temp-navigation.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        Temple
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;8 AM-9 AM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        Hindi Srimad Bhagwatm
                                                                    </h4>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/profile.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        His Grace Premanjan Chaitanya Prabhu
                                                                    </div>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/nav/temp-navigation.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        Balaram Hall ( Goshala)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;4:30 PM-5:30 PM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        Hindi Bhagwat Gita
                                                                    </h4>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/profile.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        His Grace Madhuha Prabhu
                                                                    </div>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/nav/temp-navigation.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        Conference Hall
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;5:30 PM-6:30 PM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        English Bhagwat Gita
                                                                    </h4>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/profile.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        Her Grace Daivi Shakti Mataji
                                                                    </div>
                                                                    <div className="date-time-stamp my-2 d-flex align-items-center">
                                                                        <span className="temp-time me-2">
                                                                            <img
                                                                                src="/images/nav/temp-navigation.svg"
                                                                                alt=""
                                                                                className="temp-t"
                                                                            />
                                                                        </span>
                                                                        Conference Hall
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;7:30 AM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">Guru Puja</h4>
                                                                    <p className="mb-0 notify-paragraph">
                                                                        
                                                                        His Grace Mukti Data Prabhu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;7:00 PM
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        Sandhya Arati
                                                                    </h4>
                                                                    <p className="mb-0 notify-paragraph">
                                                                        
                                                                        His Grace Dadi Baksha Prabhu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 py-3 notify-list">
                                                            <p className="mb-0 text-end d-time pe-4">
                                                                <img
                                                                    src="/images/nav/temp-time.svg"
                                                                    alt=""
                                                                    className="temp-t"
                                                                    style={{ width: 12 }}
                                                                />
                                                                &nbsp;&nbsp;
                                                            </p>
                                                            <div className="d-flex">
                                                                <img
                                                                    src="/images/iskcon-fevicon.png"
                                                                    alt=""
                                                                    className="notify me-3"
                                                                />
                                                                <div>
                                                                    <h4 className="notify-heading mb-1">
                                                                        Tomorrow's Mangal Arati
                                                                    </h4>
                                                                    <p className="mb-0 notify-paragraph">
                                                                        
                                                                        His Grace Devakinandan Prabhu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </ul>
                                    </li> */}
                                    {/* <div
                                        className="modal fade"
                                        id="notificationModal"
                                        tabIndex={-1}
                                        aria-labelledby="LoaderModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content noti-modal">
                                                <div className="modal-body text-center" />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <li>
                                        <div className="dropdown app-dwnld">
                                            <div className="d-flex align-items-center">
                                                <div className="d-md-flex d-none flex-column me-2 user-detail gap-1 text-end">
                                                    <span className="font-10 text-uppercase">Your Profile</span>
                                                    <span className="primary-f-color text-capitalize">
                                                        kabixi7867
                                                    </span>
                                                </div>
                                                <div
                                                    className="user-pic d-flex gap-2 align-items-center cursor-pointer ms-2 backdroClk"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <img
                                                        src="/images/user-profile.png"
                                                        className="user"
                                                        id="hamburger"
                                                        alt=""
                                                    />
                                                    <img
                                                        src="/images/arrow-down-user.svg"
                                                        alt=""
                                                        className="arrow-down d-md-flex d-none"
                                                    />
                                                    <div className="hamburger d-md-none d-none align-items-center justify-content-center">
                                                        <img
                                                            src="/images/nav/hamburger.svg"
                                                            alt=""
                                                            className="hambr"
                                                            id="hamburger"
                                                        />
                                                    </div>
                                                </div>
                                                <ul
                                                    className="dropdown-menu dropdown-menu-end"
                                                    aria-labelledby="dropdownMenuButton2"
                                                >
                                                    <span className="polygon">
                                                        <img
                                                            src="/images/polygon.svg"
                                                            alt=""
                                                            className="polygon"
                                                        />
                                                    </span>
                                                    <div className="drop-bx px-0 py-4 user-box-wrapper">
                                                        <div className="w-100 d-flex d-md-none justify-content-end align-items-center mb-3 px-4">
                                                            <span
                                                                className="close-menu d-flex align-items-center justify-content-center"
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                <img
                                                                    src="/images/nav/close-menu.svg"
                                                                    alt=""
                                                                    className="close-menu-wrap"
                                                                    id="close-mob-menu"
                                                                />
                                                            </span>
                                                        </div>
                                                        <li className="d-flex flex-column">
                                                            <div className="w-100 px-4 d-flex align-items-center">
                                                                <img
                                                                    src="/images/user-profile.png"
                                                                    className="user-drop rounded-circle"
                                                                    alt=""
                                                                />
                                                                <div className="d-flex flex-column">
                                                                    <p className="user-name mb-0 text-capitalize">
                                                                        kabixi7867
                                                                    </p>
                                                                    <p className="user-email mb-0">
                                                                        kabixi7867@trazeco.com
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="user-list pt-3">
                                                                <ul>
                                                                    <li className="px-4 py-3">
                                                                        <a
                                                                            href="/profile"
                                                                            className="d-flex align-items-center justify-content-between"
                                                                        >
                                                                            <div className=" d-flex flex-grow-1 align-items-center">
                                                                                <div className="wd-40 list-icon">
                                                                                    <img src="/images/profile.svg" />
                                                                                </div>
                                                                                <div className="list-content">My Profile</div>
                                                                            </div>
                                                                            <div className="wd-40 list-arrow">
                                                                                <img src="/images/arrow-user.svg" />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="px-4 py-3">
                                                                        <a
                                                                            href="/history"
                                                                            className="d-flex align-items-center justify-content-between"
                                                                        >
                                                                            <div className=" d-flex flex-grow-1 align-items-center">
                                                                                <div className="wd-40 list-icon">
                                                                                    <img src="/images/history.svg" />
                                                                                </div>
                                                                                <div className="list-content">
                                                                                    Donation History
                                                                                </div>
                                                                            </div>
                                                                            <div className="wd-40 list-arrow">
                                                                                <img src="/images/arrow-user.svg" />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="px-4 py-3">
                                                                        <a
                                                                            href="/contact"
                                                                            className="d-flex align-items-center justify-content-between"
                                                                        >
                                                                            <div className=" d-flex flex-grow-1 align-items-center">
                                                                                <div className="wd-40 list-icon">
                                                                                    <img src="/images/contact-us.svg" />
                                                                                </div>
                                                                                <div className="list-content">Contact Us</div>
                                                                            </div>
                                                                            <div className="wd-40 list-arrow">
                                                                                <img src="/images/arrow-user.svg" />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="px-4 py-3 border-top">
                                                                        <a className="d-flex align-items-center justify-content-between cursor-pt">
                                                                            <div className=" d-flex flex-grow-1 align-items-center">
                                                                                <div className="wd-40 list-icon">
                                                                                    <img src="/images/logout.svg" />
                                                                                </div>
                                                                                <div className="list-content">Sign out</div>
                                                                            </div>
                                                                            <div className="wd-40 list-arrow">
                                                                                <img src="/images/arrow-user.svg" />
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </header>
                </div>
            </div>

            <div className="container-fluid navigation-menu">
                <div className="container">
                <nav className="d-flex gap-3">
                    <div className={"flex-grow-1"+(allowHoverMenu.includes(location.pathname)?' contain-menu':'')}>
                    <ul className="nav nav-pills cs-nav main-shadow bg-white align-items-center justify-content-between d-md-flex d-none">
                        <li className={"nav-item-menu d-md-flex"+(location.pathname==='/'?' current  d-none':'')}>
                        <Link className="nav-link" aria-current="page" target="_self" to="/">
                            <img src="/images/nav/home.svg" className="d-md-none d-block" />
                            <span> Home</span>
                        </Link>
                        </li>
                        <li className={"nav-item-menu dropdown main-menu"+(location.pathname==='/home'?' current  d-none':'')}>
                        <Link
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            role="button"
                            aria-expanded="false"
                            onClick={()=>redirectFn('home')}
                        >
                            <img
                            src="/images/nav/sparkle.svg"
                            className="d-md-none d-block"
                            />
                            <span>ISKCON</span>
                        </Link>
                        <div className="dropdown-menu">
                            <ul className="drop-menu pt-4">
                            <li>
                                <h3>Explore our various services</h3>
                                <div className="d-flex gap-5">
                                <div className="d-flex flex-column">
                                    <Link
                                    to="/home/about-us"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>About Us</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/home/the-history"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>The History</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/home/philosophy"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Philosophy</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                </div>
                                <div className="d-flex flex-column">
                                    <Link
                                    to="/home/founder"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Founder</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/home/why-krishna-conscious-movement"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Why This Krishna Conscious Movement?</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </li>
                        <li className={"nav-item-menu d-md-flex"+(location.pathname==='/social'?' current  d-none':'')}>
                        <Link
                            className="nav-link"
                            aria-current="page"
                            target="_self"
                            to="/social"
                        >
                            <img src="/images/nav/home.svg" className="d-md-none d-block" />
                            <span>Social</span>
                        </Link>
                        </li>
                        <li className="nav-item-menu dropdown main-menu">
                        <a
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-expanded="false"
                        >
                            <img
                            src="/images/nav/sparkle.svg"
                            className="d-md-none d-block"
                            />
                            <span>Media</span>
                        </a>
                        <div className="dropdown-menu">
                            <ul className="drop-menu pt-4">
                            <li>
                                <h3>Explore our various services</h3>
                                <div className="d-flex gap-5">
                                <div className="d-flex flex-column">
                                    {/* <a
                                    target="_self"
                                    href="/live-darshan"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Live Darshan</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </a> */}
                                    <Link
                                    to="/daily-darshan-gallery"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Daily Darshan Gallery</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                </div>
                                <div className="d-flex flex-column">
                                    <a
                                    target="_self"
                                    href="/kirtan-lectures"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Kirtan &amp; Lectures</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </a>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </li>
                        <li className={"nav-item-menu dropdown main-menu"+(location.pathname==='/festival'?' current  d-none':'')}>
                        <Link
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            role="button"
                            aria-expanded="false"
                            onClick={()=>redirectFn('festival')}
                        >
                            <img
                            src="/images/nav/sparkle.svg"
                            className="d-md-none d-block"
                            />
                            <span>Festivals</span>
                        </Link>
                        <div className="dropdown-menu">
                            <ul className="drop-menu pt-4">
                            <li>
                                <h3>Explore our various services</h3>
                                <div className="d-flex gap-5">
                                <div className="d-flex flex-column">
                                    <Link
                                    to="/festival/sri-krishna-janmastmi"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Sri Krishna Janmastmi</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/mundia-purnima-festival"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Mundia Purnima Festival</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/ratha-yatra-festival"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Ratha Yatra Festival</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/ganga-puja"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Ganga Puja</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                </div>
                                <div className="d-flex flex-column">
                                    <Link
                                    to="/festival/radhastmi"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Sri Radhashtami</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/panihati-dahi-chida"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Panihati Dahi Chida</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/srila-prabhupadas-vyasa-puja"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Srila Prabhupadas Vyasa Puja</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                    <Link
                                    to="/festival/srila-prabhupad-disappearance-day"
                                    className="d-flex align-items-center"
                                    >
                                    <span className="link-nav-bg me-3">
                                        <img src="/images/membership.svg" alt="" />
                                    </span>
                                    <div className="drp-dwn-content d-flex flex-column">
                                        <span>Srila Prabhupada Disappearance Day</span>
                                        <span>Sri Sri Radha Shyamsundar Mandir</span>
                                    </div>
                                    </Link>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </li>
                        {/* <li className="nav-item-menu dropdown main-menu">
                        <a
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-expanded="false"
                        >
                            <img
                            src="/images/nav/sparkle.svg"
                            className="d-md-none d-block"
                            />
                            <span>Facility</span>
                        </a>
                        <div className="dropdown-menu">
                            <ul className="drop-menu pt-4">
                            <li>
                                <h3>Explore our various services</h3>
                                <div className="d-flex gap-5">
                                    <div className="d-flex flex-column">
                                        <a
                                        target="_self"
                                        href="/vaishnava-calendar"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Vaishnana Calendar</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                        <a
                                        target="_self"
                                        href="/visit-vrindavan/guest-house"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Guest House Booking</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                        <a
                                        target="_self"
                                        href="/visit-vrindavan/dining-options"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Dining Options</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                        <a
                                        target="_self"
                                        href="/visit-vrindavan/holy-dham-etiquette"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Holy Dham Etiquette</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <a
                                        target="_self"
                                        href="/visit-vrindavan/safety-tips"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Safety Tips</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                        <a
                                        target="_self"
                                        href="/visit-vrindavan/visitor-facility"
                                        className="d-flex align-items-center"
                                        >
                                        <span className="link-nav-bg me-3">
                                            <img src="/images/membership.svg" alt="" />
                                        </span>
                                        <div className="drp-dwn-content d-flex flex-column">
                                            <span>Visitor Facility</span>
                                            <span>Sri Sri Radha Shyamsundar Mandir</span>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </li> */}
                        <li className={"nav-item-menu d-md-flex"+(location.pathname==='/donate'?' current  d-none':'')}>
                        <Link
                            className="nav-link"
                            aria-current="page"
                            to="/donate"
                        >
                            <img src="/images/nav/home.svg" className="d-md-none d-block" />
                            <span> Donate</span>
                        </Link>
                        </li>
                        <li className={"nav-item-menu d-md-flex"+(location.pathname==='/blog'?' current  d-none':'')}>
                        <Link
                            className="nav-link"
                            aria-current="page"
                            to="/blog"
                        >
                            <img src="/images/nav/home.svg" className="d-md-none d-block" />
                            <span> Blog</span>
                        </Link>
                        </li>
                        {
                            allowHoverMenu.includes(location.pathname)?
                            <li className="slider" />:''
                        }
                    </ul>
                    <ul className="nav nav-pills cs-nav main-shadow bg-white align-items-center justify-content-between d-md-none d-flex mobile-menu">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">
                                <img src="/images/nav/home.svg" className="d-md-none d-block" />
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to="/social">
                                <img src="/images/nav/sparkle.svg" className="d-md-none d-block" />
                                <span>Social</span>
                            </Link>
                        </li>
                        <li className="nav-item d-none d-md-flex">
                            <Link className="nav-link">
                                Media
                            </Link>
                        </li>
                        <li className="nav-item ">
                            <Link className="nav-link" to="/donate">
                                <img src="/images/nav/gift.svg" className="d-md-none d-block" />
                                <span>Donate</span>
                            </Link>
                        </li>
                        <li className="nav-item dropdown main-menu">
                            <Link
                                className="nav-link dropdown-toggle"
                                id="dropdownMobile"
                                data-bs-toggle="dropdown"
                                role="button"
                                aria-expanded="false"
                            >
                                <img src="/images/nav/star.svg" className="d-md-none d-block" />
                                <span>Festivals</span>
                            </Link>
                            <div className="dropdown-menu">
                                <ul className="drop-menu pt-4 w-100" id="dropdownMobile">
                                <li>
                                    <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                                    <h3 className="mb-0">Festivals</h3>
                                    <span ref={festivalCloseRef}
                                        className="close-menu d-flex align-items-center justify-content-center temp-close"
                                        data-bs-toggle="dropdown"
                                    >
                                        <img
                                        src="/images/nav/close-menu.svg"
                                        alt=""
                                        className="close-menu-wrap"
                                        style={{ maxWidth: 15 }}
                                        />
                                    </span>
                                    </div>
                                    <div className="d-flex mob-menu w-100">
                                    <ul className="w-100">
                                        <li>
                                            <Link to="/festival/sri-krishna-janmastmi" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Sri Krishna Janmastmi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/mundia-purnima-festival" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Mundia Purnima Festival
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/ratha-yatra-festival" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Ratha Yatra Festival
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/ganga-puja" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Ganga Puja
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/radhastmi" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Sri Radhashtami
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/panihati-dahi-chida" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Panihati Dahi Chida
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/srila-prabhupadas-vyasa-puja" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Srila Prabhupadas Vyasa Puja
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/festival/srila-prabhupad-disappearance-day" onClick={()=>hideModal('festival')}>
                                                <img
                                                src="/images/nav/arrow-list.svg"
                                                alt=""
                                                className="arrow-list me-2"
                                                />
                                                Srila Prabhupada Disappearance Day
                                            </Link>
                                        </li>
                                    </ul>
                                    </div>
                                </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item pe-3 d-none d-md-flex">
                            <Link className="nav-link">
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item pe-0 pe-md-3 d-md-none d-flex" id="dropdownMore">
                            <Link className="nav-link">
                                <img src="/images/nav/more.svg" className="d-md-none d-block" />
                                <span>More</span>
                            </Link>
                            <div className="w-100 position-fixed bg-white" id="more-menu">
                                <div className="w-100 d-flex justify-content-between align-items-center mb-3 px-4 py-4">
                                    <h3 className="mb-0">More</h3>
                                    <span ref={moreCloseRef} className="close-menu d-flex align-items-center justify-content-center temp-close" data-bs-toggle="dropdown">
                                        <img
                                            src="/images/nav/close-menu.svg"
                                            alt=""
                                            className="close-menu-wrap"
                                            style={{ maxWidth: 15 }}
                                        />
                                    </span>
                                </div>
                                <div className="d-flex mob-menu w-100 px-3 ">
                                    <div
                                        className="accordion d-flex flex-column gap-4 w-100"
                                        id="accordionExample"
                                    >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>Media</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul className="w-100 d-flex gap-4 flex-column">
                                                        {/* <li>
                                                            <a target="_self" href="/live-darshan">
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Live Darshan
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <Link to="/daily-darshan-gallery" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Daily Darshan Gallery
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/kirtan-lectures" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Kirtan &amp; Lectures
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSocial">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseSocial"
                                                    aria-expanded="false"
                                                    aria-controls="collapseSocial"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>Social</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseSocial"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingSocial"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    {/* <ul className="w-100 d-flex gap-4 flex-column"> */}
                                                    <ul className="d-flex gap-5">
                                                        <li>
                                                            <Link to="https://www.facebook.com/iskconvarachha/" target="_blank" className="social-icon d-flex align-items-center justify-content-center" onClick={()=>hideModal('more')}>
                                                            <img src="/images/facebook_icon.svg" alt="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://twitter.com/iskconvarachha" target="_blank" className="social-icon d-flex align-items-center justify-content-center" onClick={()=>hideModal('more')}>
                                                            <img src="/images/twitter_icon.svg" alt="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://www.instagram.com/iskconvarachha" target="_blank" className="social-icon d-flex align-items-center justify-content-center" onClick={()=>hideModal('more')}>
                                                            <img src="/images/instagram_icon.svg" alt="" />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://www.youtube.com/c/murtimandasgkg" target="_blank" className="social-icon d-flex align-items-center justify-content-center" onClick={()=>hideModal('more')}>
                                                            <img src="/images/youtube_icon.svg" alt="" />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    {/* </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>Blog</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul className="w-100 d-flex gap-4 flex-column">
                                                        <li>
                                                            <Link to="/blog" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Blog
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>ISKCON</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul className="w-100 d-flex gap-4 flex-column">
                                                        <li>
                                                            <Link to="/home/about-us" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                About Us
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home/the-history" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                The History
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home/philosophy" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Philosophy
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home/founder" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Founder
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home/why-krishna-conscious-movement" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Why This Krishna Conscious Movement?
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFour"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>Visit Vrindavan</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseFour"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingFour"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul className="w-100 d-flex gap-4 flex-column">
                                                        <li>
                                                            <a target="_self" href="/vaishnava-calendar">
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Vaishnana Calendar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_self"
                                                                href="/visit-vrindavan/guest-house"
                                                            >
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Guest House Booking
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_self"
                                                                href="/visit-vrindavan/dining-options"
                                                            >
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Dining Options
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_self"
                                                                href="/visit-vrindavan/holy-dham-etiquette"
                                                            >
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Holy Dham Etiquette
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_self"
                                                                href="/visit-vrindavan/safety-tips"
                                                            >
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Safety Tips
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_self"
                                                                href="/visit-vrindavan/visitor-facility"
                                                            >
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Visitor Facility
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFive"
                                                >
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <span>Contact us</span>
                                                        <span className="accordian-arrow">
                                                            <img src="/images/nav/arrow-dropdown.svg" />
                                                        </span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseFive"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingFive"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul className="w-100 d-flex gap-4 flex-column">
                                                        <li>
                                                            <Link to="/contact" onClick={()=>hideModal('more')}>
                                                                <img
                                                                    src="/images/nav/arrow-list.svg"
                                                                    alt=""
                                                                    className="arrow-list me-2"
                                                                />
                                                                Contact us
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    </div>
                    <div className="nav-right-element d-lg-flex d-sm-none d-none gap-3">
                    <a
                        href="https://www.google.com/maps/place/ISKCON+Varachha/@21.2056988,72.8536897,258m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!2sISKCON+Varachha!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t!3m5!1s0x3be04f01bb30cc4b:0x108a81cd75cd881f!8m2!3d21.205831!4d72.853817!16s%2Fg%2F11f3vc565t?hl=en&entry=ttu"
                        target="_blank"
                        className="nav-right-help-bx d-flex align-items-center justify-content-center primary-bg-color"
                    >
                        <img src="/images/navigate.svg" alt="" className="call-ico" />
                    </a>
                    <Link
                        to="/contact"
                        className="nav-right-help-bx d-flex align-items-center justify-content-center primary-bg-color"
                    >
                        <img src="/images/chat.svg" alt="" className="chat-ico" />
                    </Link>
                    </div>
                </nav>
                </div>
            </div>
        </>
    )
}
